import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { loadCss } from "../../site";

const Kimp = ({ isMobile }) => {
  const { exchange } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState(exchange || "upbit");
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const api = process.env.REACT_APP_API;
  const valueRefs = useRef({}); // 값이 표시되는 span 참조 저장
	const marqueeRef = useRef(null);

  axios.defaults.withCredentials = true;

  const nav = (tab) => {
    setTab(tab);
    navigate(`/insight/kimp/${tab}`);
  };

  // Fetch data once
  const fetchData = async () => {
    try {
      const response = await axios.get(`${api}/insight/kimp`, { params: { exchange: tab } });
      const r = response.data;
			//console.log(r);
      if (data.kimp) {
        updateValues(r.data?.kimp || {});
      }

      setData(r.data || {});
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Update only value spans directly
  const updateValues = (newData) => {
    Object.entries(newData).forEach(([token, exchanges]) => {
      Object.entries(exchanges).forEach(([exchange, value]) => {
        const refKey = `${token}-${exchange}`;
        const valueElement = valueRefs.current[refKey];
        if (valueElement) {
          const lastValue = parseFloat(valueElement.dataset.delta || "0");
          const newValue = parseFloat(value);

          if (lastValue !== newValue) {
            const deltaClass =
              newValue > lastValue ? "positive" : newValue < lastValue ? "negative" : "neutral";

            // Update the span value and dataset
            valueElement.textContent = newValue.toFixed(2);
            valueElement.dataset.delta = newValue; // Store new value in data-delta

            // Add flash effect with data-delta class
            valueElement.setAttribute("data-delta", deltaClass);
            valueElement.classList.add("flash");
            setTimeout(() => {
              valueElement.classList.remove("flash");
            }, 1000); // Match animation duration in CSS
          }
        }
      });
    });
  };

  const marquee = () => {
		if (isMobile) {
			const marquee = marqueeRef.current;
			const content = marquee.querySelector("ul.content");
			const contentWidth = content.scrollWidth;
			const containerWidth = marquee.offsetWidth;
			const speed = 100;
			const duration = contentWidth / speed;
			content.style.animation = `marquee ${duration}s linear infinite`;
			if (contentWidth > containerWidth) {
				const clone = content.cloneNode(true);
				marquee.appendChild(clone);
			}
		}
  };

  useEffect(() => {
    fetchData(); // 초기 데이터 가져오기
    const interval = setInterval(fetchData, 5000); // 5초마다 업데이트
    return () => clearInterval(interval); // 언마운트 시 인터벌 정리
  }, [tab]);

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);


	useEffect(() => {
		if (!loading && isMobile && data.rates) marquee();
	}, [loading, isMobile, data.rates]);

  // 로딩 화면
  if (!stylized || loading) {
    return <div className="loading"><span className="loader">Loading…</span></div>;
  }

  // Render for mobile (ul list) or desktop (table)
  return (
    <section className={`insight kimp${isMobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 김프</title>
      </Helmet>
      <h1>김프</h1>
			{data.rates&&(
			<div className="rate krw marquee" ref={marqueeRef}>
				<ul className="content"><li><strong>환율</strong></li>
				{Object.entries(data.rates).map(([key, value]) => (
					<li key={key} className="crypto">
					<span className="crypto coin" data-symbol={key}></span>
					<strong>1</strong>
					<small>{key}</small>
					<span>=</span>
					<strong><span data-currency="₩"></span> {value.toLocaleString()}</strong>
					</li>
				))}
				</ul>
			</div>
			)}

      <ul className="nav nav-tabs">
        {["upbit", "bithumb", "coinone"].map((exchangeName) => (
          <li key={exchangeName} className="nav-item">
            <button className={`nav-link crypto${tab === exchangeName ? " active" : ""}`} data-exchange={exchangeName} onClick={() => nav(exchangeName)}>{exchangeName.charAt(0).toUpperCase() + exchangeName.slice(1)}</button>
          </li>
        ))}
      </ul>
      {isMobile ? (
        <ul className="kimp-list">
          {Object.entries(data.kimp).map(([token, exchanges]) => (
            <li key={token} className="card">
              <h3 className="card-header crypto coin" data-symbol={token.toLowerCase()}>{token}</h3>
              <ul className="card-body">
                {data.exchange.map((exchange) => {
                  const refKey = `${token}-${exchange}`;
                  const value = exchanges[exchange] || 0;
                  return (
                    <li key={exchange}>
                      <span className="crypto" data-exchange={exchange.toLowerCase()}>{exchange}</span>
                      <span ref={(el) => { if (el) valueRefs.current[refKey] = el; }} data-delta={value}>{value.toFixed(2)}</span>
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Symbol</th>
              {data.exchange.map((exchange) => (
                <th key={exchange}>
                  <span className="crypto" data-exchange={exchange.toLowerCase()}><br />{exchange}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.kimp).map(([token, exchanges]) => (
              <tr key={token}>
                <td>
                  <strong className="crypto coin" data-symbol={token.toLowerCase()}>{token}</strong>
                </td>
                {data.exchange.map((exchange) => {
                  const refKey = `${token}-${exchange}`;
                  const value = exchanges[exchange] || 0;
                  return (
                    <td key={exchange}><span ref={(el) => { if (el) valueRefs.current[refKey] = el; }} data-delta={value}>{value.toFixed(2)}</span></td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default Kimp;
