import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Company = ({navi}) => {
  useEffect(() => {
		navi("/about");
	},[navi]);
	return (
		<section className="about intro">
      <Helmet>
        <title>코글 - 회사소개</title>
      </Helmet>
      <h1>회사소개</h1>
			<div className="container">
			</div>
		</section>
	);
};
export default Company;
