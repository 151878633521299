import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import axios from "axios";
import Editor from "./editor"; // Editor 컴포넌트 import

import IconLabel from "@mui/icons-material/LabelOutlined";
import IconEdit from '@mui/icons-material/BorderColorTwoTone';
import IconSave from '@mui/icons-material/Save';

const About = ({ isMobile }) => {
  const [error, setError] = useState(false);
  const [tab, setTab] = useState("term");
  const [data, setData] = useState({});
	const [tier, setTier] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Editing 모드 상태
  const { auth } = useAuth();
  const api = process.env.REACT_APP_API;
  axios.defaults.withCredentials = true;

  // Fetch data when tab changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {type: tab};
        const response = await axios.get(`${api}/dash/about`, { headers:{ Authorization: `Bearer ${auth?.profile?.token}`}, params });
				//console.log(response.data);
        if (response.data.error) throw new Error(response.data.error);
        if (response.data.content) {
          setData((prev) => ({
            ...prev,
            [tab]: response.data.content,
          }));
					if (tab==="tier") setTier(response.data.list);
        }
        setError(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(true);
      }
    };

    if (!data[tab]) {
      fetchData();
    }
  }, [tab]);

	const handleTab = (tab) => {
		setIsEditing(false);
		setTab(tab);
	}

  const update = async (content) => {
    try {
			const payload = new FormData();
			payload.append("type", tab);
			payload.append("content", content);
      const response = await axios.post(`${api}/dash/update-about`, payload, { headers: { Authorization: `Bearer ${auth?.profile?.token}`, "Content-Type": "multipart/form-data"}});

      if (response.data.error) throw new Error(response.data.error);
      setData((prev) => ({ ...prev, [tab]: content }));
      setIsEditing(false);
    } catch (err) {
      console.error("Error saving data:", err);
			const t=document.querySelector("section.about .tab-pane");
			t.classList.add("wiggle");
			setTimeout(()=>t.classList.remove("wiggle"),1000);
    }
  };

  const updateTier = async (event) => {
		const form = event.target;
    event.preventDefault();
    try {
			const payload = new FormData();
			payload.append("id", form.id.value);
			payload.append("name", form.name.value);
			payload.append("xp", form.xp.value);
			payload.append("description", form.description.value);
			payload.append("benefit", form.benefit.value);
      const response = await axios.post(`${api}/dash/update-tier`, payload, { headers: { Authorization: `Bearer ${auth?.profile?.token}`, "Content-Type": "multipart/form-data"}});
      if (response.data.error) throw new Error(response.data.error);
			if (response.data.update) {
				const s = form.querySelector("li.status");
				s.classList.add("alert","alert-success");
				s.innerText="업데이트 되었습니다";
				setTimeout(()=>{s.classList.remove("alert","alert-success");s.innerText=""}, 3000);
			}
    } catch (err) {
      console.error("Error saving data:", err);
			setError(`${err}`);
			setTimeout(()=>setError(false), 2000);
			form.classList.add("wiggle");
			setTimeout(()=>form.classList.remove("wiggle"), 1000);
    }
  };

  return (
    <section className={`about ${isMobile ? " mobile" : ""}`}>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <button type="button" className={`btn btn-sm nav-link ${tab === "term" ? "active" : ""}`} onClick={() => handleTab("term")} role="tab" >{isMobile||<IconLabel />} 약관</button>
        </li>
        <li className="nav-item">
          <button type="button" className={`btn btn-sm nav-link ${tab === "privacy" ? "active" : ""}`} onClick={() => handleTab("privacy")} role="tab" >{isMobile||<IconLabel />} 개인정보보호</button>
        </li>
        <li className="nav-item">
          <button type="button" className={`btn btn-sm nav-link ${tab === "tier" ? "active" : ""}`} onClick={() => handleTab("tier")} role="tab" >{isMobile||<IconLabel />} 등급/티어</button>
        </li>
        <li className="nav-item">
          <button type="button" className={`btn btn-sm nav-link ${tab === "welcome" ? "active" : ""}`} onClick={() => handleTab("welcome")} role="tab" >{isMobile||<IconLabel />} 가입환영</button>
        </li>
      </ul>

      <div className={`tab-content ${tab}`}>
        <div id={`tab-${tab}`} className={`tab-pane fade show active`} role="tabpanel">
					{tab==="tier" && tier && (tier?.length>0) && (
						<table className="table tier">
							<thead>
								<tr>
									<th>티어</th>
									<th>XP</th>
									<th>설명</th>
									<th>혜택</th>
								</tr>
							</thead>
							<tbody>
								{tier.map((e,n)=>(
									<tr key={n}>
										<td>
											<button className="btn w-100 dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">{e.name}</button>
											<div className="dropdown-menu">
												<form name="update-tier" className="form form-inline" onSubmit={updateTier}>
													<fieldset>
														<ul>
															<li className="input-group"><span className="input-group-text">이름</span><input type="text" className="form-control" name="name" defaultValue={e.name} /></li>
															<li className="input-group"><span className="input-group-text">xp</span><input type="number" className="form-control" name="xp" defaultValue={e.xp} /></li>
															<li><span>설명</span><textarea className="form-control" name="description" rows="3" defaultValue={e.description}></textarea></li>
															<li><span>혜택</span><textarea className="form-control" name="benefit" rows="3" defaultValue={e.benefit}></textarea></li>
															<li className={`status${error?(" alert alert-danger"):("")}`}>{error??""}</li>
															<li><input type="hidden" name="id" value={e.id} /><button type="submit" className="btn btn-sm btn-success"><IconSave /> 저장하기</button></li>
														</ul>
													</fieldset>
												</form>
											</div>
										</td>
										<td>{e.xp}</td>
										<td>{e.description}</td>
										<td>{e.benefit}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
          {isEditing ? (
            <Editor content={data[tab] || ""} onSave={update} onCancel={() => setIsEditing(false)} />
          ) : (
            <>
						<div className="edit"><button type="button" className="btn btn-sm btn-primary" onClick={() => setIsEditing(true)} ><IconEdit style={{fontSize:"1rem"}}/> 편집하기 </button></div>
						<div dangerouslySetInnerHTML={{ __html: data[tab] || "" }} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
