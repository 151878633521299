import React, { useState, useEffect, useCallback, useRef } from "react";
import { avatar, ago } from "../site";
import { Editor } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import useDraft from "../draft";
import axios from "axios";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import { More } from "../component/pagination";

import IconUser from "@mui/icons-material/AccountCircleTwoTone";
import IconRe from "@mui/icons-material/ExpandMore";
import ThumbDown from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUp from "@mui/icons-material/ThumbUpOffAlt";

const Comment = ({ isMobile, auth, payload }) => {
  const api = process.env.REACT_APP_API;
  const { state, update, cancel, reset } = useDraft(api);
  const [comments, setComments] = useState({ list: [], count: 0, more: false });
  const [note, setNote] = useState(false);
  const [reply, setReply] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const loadingRef = useRef(false);
	axios.defaults.withCredentials = true;

  // 댓글 데이터 로드
  const fetchData = useCallback(async (page) => {
		if (loadingRef.current) return;
		loadingRef.current = true;
		try {
			const response = await axios.get(`${api}/comment/`, { params: { name: payload.t, id: payload.i, page } });
			if (response.data.error) throw new Error(response.data.error);
			const { data, count, more } = response.data;
			console.log(response.data);
			setComments((prev) => ({
				list: page === 1 ? data || [] : [...prev.list, ...(data || [])],
				count: count || 0,
				more: more || false,
			}));
		} catch (err) {
			console.error("Error fetching comments:", err);
		} finally {
			loadingRef.current = false;
		}
	},[api, payload]);


	const add = async (event) => {
    event.preventDefault();
		setIsDisabled(true);
    //const content = JSON.stringify(convertToRaw(state.getCurrentContent()));
		const content = stateToHTML(state.getCurrentContent());
    const formData = new FormData();
		payload.p=reply??0;
    formData.append("member_id", auth?.id ?? 0);
    formData.append("content", content);
    formData.append("payload", btoa(JSON.stringify(payload)));
    try {
      const response = await axios.post(`${api}/comment/add`, formData);
      if (response.data.error) throw new Error(response.data.error);
			setNote("답글이 추가 되었습니다");
			console.log(response.data);
			reset();
			fetchData(1);
    } catch (error) {
      console.error("폼 제출 오류:", error);
    } finally {
			setTimeout(()=>{setNote(false);setIsDisabled(false)},2000);
		}
  };

	const feedback = useCallback(async (t,i,e) => {
		const p = e.target.closest("dd.reaction");
		const like = p.querySelector("span.like");
		const dislike = p.querySelector("span.dislike");
    const formData = new FormData();
    formData.append("member_id", auth?.id ?? 0);
		formData.append("payload",btoa(JSON.stringify({n:"lounge",t,i})));
		formData.append("reply",true);
    try {
      const response=await axios.post(`${api}/react/`,formData);
      const r=response.data;
      if (r.error) throw new Error(r.error);
			//console.log(r);
			if (r.add) {
				like.textContent = r.reaction.like;
				dislike.textContent = r.reaction.dislike;
			}
    } catch (error) {
      console.error("Error fetching data:", error);
    }
	}, [api, auth.id]);

	const re = (id) => {
		setNote(false);
		if (auth?.id > 0) {
			setReply(id);
			reset();
		} else {
			const el = document.querySelector("#sign-in.modal");
			if (el) {
				let modal = Modal.getInstance(el);
				if (!modal) modal = new Modal(el);
				modal.show();
			}
		}
	}

	const onChange = (editorState) => {
		if (auth?.id > 0) {
			update(editorState);
			const contentState = editorState.getCurrentContent();
			const plainText = contentState.getPlainText();
			setIsDisabled(plainText.trim().length < 2);
		} else {
			const el = document.querySelector("#sign-in.modal");
			if (el) {
				let modal = Modal.getInstance(el);
				if (!modal) modal = new Modal(el);
				modal.show();
			}
			reset();
		}
	}

  const undo = () => {
    cancel();
		reset();
		setIsDisabled(true);
		setNote(false);
		if (reply!==0) setReply(0);
  };


  // 초기 로드
  useEffect(() => {
    fetchData(1); // 첫 페이지 로드
  }, [fetchData]);

  return (
		<div className="comment">
			<div className="header">
				<h4> 댓글 : {comments.count} 개</h4>
				{reply !== 0 &&(
					<button type="button" className="btn btn-sm text-primary" onClick={()=>re(0)}>댓글 <IconRe style={{fontSize:14}}/> </button>
				)}
			</div>
			{reply === 0 && (
			<form name="add-comment" onSubmit={add}>
				<div className="author">
				{auth?.id ? (avatar(auth.profile.avatar,{size:40})):(
					<button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#sign-in"><IconUser style={{fontSize:"48px",color:"var(--cogle-color)"}} /></button>
				)}
				</div>

				<div className="editor">
					<Editor editorState={state} onChange={onChange} />
					{ auth?.id > 0 && (
					<div className="action">
						<button className="btn btn-sm btn-light rounded-pill" type="button" onClick={undo}>취소</button>
						<button className="btn btn-sm btn-primary rounded-pill" type="submit" disabled={isDisabled}>댓글 달기</button>
					</div>
					)}
				</div>
			</form>
			)}
			<ul className="comment">
				{comments.list?.map((e,n)=>(
				<li key={n} className={`tab-0${e.depth}`}>
					<dl>
						<dt>{avatar(e.avatar,{size:30})} {e.nick} <small className="text-muted">{ago(e.created_at)}전</small></dt>
						<dd dangerouslySetInnerHTML={{ __html: e.content }} />
						<dd className="reaction">
							<button type="button" className="btn btn-sm" onClick={(ev)=>feedback("like",e.id,ev)}><ThumbUp /> <span className="like">{(e.likes??0).toLocaleString()}</span></button>
							<button type="button" className="btn btn-sm" onClick={(ev)=>feedback("dislike",e.id,ev)}><ThumbDown /> <span className="dislike">{(e.dislikes??0).toLocaleString()}</span></button>
							{e.depth < 3 && (
								<>
								| <button className="comment btn btn-sm rounded-pill text-primary" type="button" onClick={()=>re(e.id)}>답글<IconRe style={{fontSize:14}}/></button>
								</>
							)}
							{reply === e.id && (
							<form name="add-comment" onSubmit={add}>
								<div className="author">
								{auth?.id ? (<>{avatar(auth.profile.avatar)}</>
								):(<button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#sign-in"><IconUser style={{fontSize:"32px",color:"var(--cogle-color)"}} /></button>)}
								</div>
								<div className="editor">
									<Editor editorState={state} onChange={onChange} />
									{ auth?.id > 0 && (
									<div className="action">
										{note && (<span className="text-success">{note}</span>)}
										<button className="btn btn-sm btn-light rounded-pill" type="button" onClick={undo}>취소</button>
										<button className="btn btn-sm btn-success rounded-pill" type="submit" disabled={isDisabled}>답글 달기</button>
									</div>)}
								</div>
							</form>
							)}
						</dd>
					</dl>
				</li>
				))}
			</ul>
			{comments.more && (<More load={() => fetchData(comments.more)} />)}
		</div>
  );
};

export default Comment;
