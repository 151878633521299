import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Activate from "../component/activate";
import { useAuth } from "../auth";
import { avatar, loadCss } from "../site";
import axios from "axios";

import IconVerified from "@mui/icons-material/Verified";
import IconUnverified from "@mui/icons-material/NewReleases";

const Profile = ({ isMobile }) => {
  const { auth } = useAuth();
  const { token } = useParams();
  const location = useLocation();
  const [code, setCode] = useState(location.state?.code || false); // 
  const [email, setEmail] = useState(() => sessionStorage.getItem("email") ?? false);
  const [sent, setSent] = useState(false);
  const [me, setMe] = useState(null);
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState("ko");
  const [notifications, setNotifications] = useState(false);
  const [stylized, stylize] = useState(false);

  const api = process.env.REACT_APP_API;
  axios.defaults.withCredentials = true;

  const resend = async () => {
    try {
      setSent("sending");
      const response = await axios.get(`${api}/member/resend`, { params: { code } });
      if (response.data.error) throw new Error(response.data.error);
      if (response.data.sent) {
        setSent("sent");
        sessionStorage.removeItem("email");
      }
    } catch (error) {
      console.error("재발송 실패:", error);
    }
  };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const toggleNotifications = () => {
    setNotifications(!notifications);
  };

  const verify = () => (
    <>
      <div className="alert alert-danger">이메일이 확인되지 않았습니다</div>
      <h4>안녕하세요, {email}님!</h4>
      <div>
        <p>가입 절차를 완료하려면 아래 버튼을 눌러 새로운 확인 메일을 받아주세요.</p>
        {!sent && (
          <button type="button" className="btn btn-primary" onClick={resend}>
            확인 메일 재발송하기
          </button>
        )}
        {sent === "sending" && (
          <button type="button" className="btn btn-secondary">
            이메일을 발송중입니다
          </button>
        )}
        {sent === "sent" && (
          <button type="button" className="btn btn-success">
            <strong>{email}</strong>로 이메일을 발송하였습니다
          </button>
        )}
        <div className="mt-3">
          <strong>📌 유의사항:</strong>
          <p className="ms-3">
            새로 받은 메일의 링크는 [24시간] 동안만 활성화됩니다.<br />
            이메일이 도착하지 않으면 스팸함을 확인하거나 이메일 주소를 다시 입력해 주세요.
          </p>
        </div>
        <p>
          문의 사항이 있으시면 언제든 [고객 지원]으로 연락해 주세요.<br />
          감사합니다. 😊
        </p>
      </div>
    </>
  );

  const basic = () => (
    <section className="basic">
      <h3>기본 사용자 정보</h3>
      <div className="profile-photo">
				{me?.avatar ? avatar(me.avatar,{size:64}) :(<>avatar</>)}
      </div>
			{me && (
      <ul className="list-group list-group-flush">
        <li className="list-group-item"><strong>별명:</strong> {me.nick}</li>
        <li className="list-group-item"><strong>이름:</strong> {me.name || "미입력"}</li>
				{me?.email && me?.status &&(<li className="list-group-item">
					<strong>이메일:</strong> {me.email} {me.status==="확인"?(<span className="text-success"><IconVerified />확인됨</span>):(<span className="text-danger"><IconUnverified />미확인</span>)}</li>)}
        <li className="list-group-item"><strong>전화번호:</strong> {me.tel || "미입력"}</li>
        <li className="list-group-item"><strong>회원 등급:</strong> {me.level === 0 ? "일반 회원" : "관리자"}</li>
        <li className="list-group-item"><strong>마지막 로그인:</strong> <time dateTime={me.accessed_at}>{new Date(me.accessed_at).toLocaleString()}</time></li>
        <li className="list-group-item"><strong>로그인 횟수:</strong> {me.accesses.toLocaleString()}회</li>
        <li className="list-group-item"><strong>가입:</strong> 
					<span className={`provider ${me.provider}`}></span>
					<time dateTime={me.created_at}>{new Date(me.created_at).toLocaleDateString()}</time>
				</li>
      </ul>
			)}
    </section>
  );

  const setting = () => (
    <section className="personal-settings">
      <h3>개인화 설정</h3>
      <div className="setting-item">
        <label>테마</label>
        <select value={theme} onChange={handleThemeChange}>
          <option value="light">라이트 모드</option>
          <option value="dark">다크 모드</option>
        </select>
      </div>
      <div className="setting-item">
        <label>언어</label>
        <select value={language} onChange={handleLanguageChange}>
          <option value="ko">한국어</option>
          <option value="en">English</option>
        </select>
      </div>
      <div className="setting-item">
        <label>알림 설정</label>
        <input
          type="checkbox"
          checked={notifications}
          onChange={toggleNotifications}
        />
        이메일 알림 받기
      </div>
    </section>
  );

	useEffect(() => {
		return loadCss("/css/profile.css",()=>stylize(true));
	}, []);

	useEffect(() => {
    const fetchProfile = async (authToken) => {
			try {
				const response = await axios.get(`${api}/member/profile`,{params:{token:authToken}});
				console.log(response.data);
				setMe(response.data.profile);
			} catch (error) {
				console.error("사용자 정보 가져오기 실패:", error);
			}
    };

    if (!token && auth?.profile?.token) fetchProfile(auth.profile.token);
  }, [token, auth, api]);

  if (!stylized) return null;

  return (
    <>
      {token ? (
        <Activate token={token} />
      ) : (
        <section className={`profile${isMobile ? " mobile" : ""}`}>
          <Helmet>
            <title>코글 - 내 프로파일</title>
          </Helmet>
          <h1>내 프로파일</h1>
          {!auth?.id && email && code && verify()}
          {me && basic()}
          {auth?.id && <Link to="/tier">회원 등급</Link>}
          {me && setting()}
        </section>
      )}
    </>
  );
};

export default Profile;
