import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../auth";
import { loadCss } from "../site";
import Member from "../component/dash/member";
import About from "../component/dash/about";
import Contact from "../component/dash/contact";
import Forum from "../component/dash/forum";
import Stash from "../component/dash/stash";
import AOS from "aos";
import "aos/dist/aos.css";

import IconDash from "@mui/icons-material/DashboardCustomize";
import IconMember from "@mui/icons-material/SupervisedUserCircleRounded";
import IconAbout from "@mui/icons-material/AssignmentOutlined";
import IconContact from "@mui/icons-material/HeadsetMicTwoTone";
import IconForum from "@mui/icons-material/ContentPasteSearchTwoTone";
import IconStash from "@mui/icons-material/CloudUploadOutlined";

const Dash = ({ isMobile, navi }) => {
  const { type } = useParams(); // Extract "type" parameter from URL
  const { auth } = useAuth();
  const [tab, setTab] = useState(type || "member"); // Default to "member" if no type in URL
  const [stylized, stylize] = useState(false);
  const navigate = useNavigate();

  const tabs = {
    member: { name: "회원", icon: IconMember, page: Member },
    about: { name: "약관/규정", icon: IconAbout, page: About },
    contact: { name: "연락처", icon: IconContact, page: Contact },
    forum: { name: "게시판", icon: IconForum, page: Forum },
    stash: { name: "저장소", icon: IconStash, page: Stash },
  };

  const shouldRedirect = useMemo(() => !(auth && auth.isStaff && auth.profile?.token), [auth]);

  // Redirect to profile page if not authorized
  useEffect(() => {
    if (shouldRedirect) navigate("/profile");
  }, [shouldRedirect, navigate]);

  useEffect(() => {
    const cleanup = loadCss("/css/dash.css", () => stylize(true));
    return cleanup;
  }, []);

  // Synchronize tab state with URL parameter
  useEffect(() => {
    if (type && tabs[type]) {
      setTab(type);
    } else {
      navigate("/dash/member"); // Redirect to default tab if type is invalid
    }
  }, [type, tabs, navigate]);

  // Refresh animations on tab change
  useEffect(() => {
    AOS.refresh();
  }, [tab]);

  const Current = useMemo(() => tabs[tab]?.page || null, [tab, tabs]);
  const handleTabChange = useCallback((tab) => {
		setTab(tab);
		navigate(`/dash/${tab}`); // Update URL when tab changes
	}, [navigate]);

  if (!stylized || shouldRedirect) { return (<div className="loading"><span className="loader">Loading…</span></div>); }

  return (
    <section className={`dash${isMobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 관리/설정</title>
      </Helmet>
      <h1>
        <IconDash /> 관리/ 설정{" "}
        {tabs[tab]?.name && <small>{tabs[tab].name}</small>}
      </h1>
      <nav className="navbar navbar-expand" data-aos="fade-left" aos-delay="800">
        <ul className="navbar-nav">
          {Object.entries(tabs).map(([key, { name, icon: Icon }]) => (
            <li key={key} className="nav-item">
              <button className={`nav-link ${key === tab ? " active" : ""}`} onClick={() => handleTabChange(key)} ><Icon style={{ fontSize: "1.2rem" }} /> {name}</button>
            </li>
          ))}
        </ul>
      </nav>
      {Current && <Current isMobile={isMobile} />}
    </section>
  );
};

export default Dash;
