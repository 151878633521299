import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMobile } from "./mobile";
import { useNav } from "./nav";

import "./style/typography.css";
import "./style/cogle.scss";
import "./style/global.css";
import "./style/post.css";
import "./style/member.css";
import "./style/alternative.css";
import "aos/dist/aos.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AOS from "aos";

import Header from "./component/header";
import Sidebar from "./component/sidebar";
import HeadUp from "./component/head-up";
import Footer from "./component/footer";

//import Chat from "./component/chat";
import SignIn from "./component/sign-in";
import SignUp from "./component/sign-up";

import Front from "./page/front";
//import NotFound from "./page/40x";
import Lost from "./page/lost";
import Profile from "./page/profile";

import Forum from "./page/popular/forum";
import News from "./page/popular/news";
import Youtube from "./page/popular/youtube";
import Twitter from "./page/popular/twitter";

import Coin from "./page/insight/coin";
import Exchange from "./page/insight/exchange";
import Future from "./page/insight/future";
import Position from "./page/insight/position";
import Kimp from "./page/insight/kimp";
import Chart from "./page/insight/chart";
import Whale from "./page/insight/whale";

import Notice from "./page/notice";
import Lounge from "./page/lounge";
import LoungeView from "./page/lounge-view";
import Meme from "./page/meme";
import MemeView from "./page/meme-view";
import Flex from "./page/flex";
import FlexView from "./page/flex-view";
import Guru from "./page/guru";
import GuruView from "./page/guru-view";

import Keyword from "./page/etc/keyword";

import Company from "./page/about/company";
import Policies from "./page/about/policies";
import Privacy from "./page/about/privacy";
import Tier from "./page/about/tier";

import Test from "./page/test";
import Dash from "./page/dash";
//import Activate from "./page/activate";

const App = () => {

	const { isMobile } = useMobile();
	const [sidebar, setSidebar] = useState(() => {
		const saved = localStorage.getItem("sidebar");
		const initial = saved ? JSON.parse(saved) : {};
		return {loaded:false, collapsed:initial.collapsed??isMobile, mobile:isMobile, ...initial};
	});

	const { nav, setNav } = useNav();
	const toggleSidebar = useCallback(() => {
		setSidebar((prev) => ({ ...prev, collapsed: !prev.collapsed }));
	}, []);

	const sidebarLoaded = useCallback(() => {
		setSidebar((prev) => ({ ...prev, loaded: true }));
	}, []);

	const hideSidebar = useCallback(() => {
		setSidebar((prev) => ({ ...prev, collapsed: true }));
	}, []);

	const navi = useCallback((path) => {
		setNav(path.split("/").filter(Boolean));
		if (sidebar.mobile) hideSidebar();
	},[setNav, sidebar.mobile, hideSidebar]);

	useEffect(() => {
		localStorage.setItem("sidebar", JSON.stringify(sidebar));
	}, [sidebar]);

	useEffect(() => {
		sessionStorage.setItem("nav", JSON.stringify(nav));
	}, [nav]);

	useEffect(() => {
		if (sidebar.mobile !== isMobile) {
			setSidebar((prev) => ({ ...prev, mobile: isMobile }));
		}
	}, [isMobile, sidebar.mobile]);

	useEffect(() => {
		AOS.init({ duration: 1000, once: true });
	}, []);

	return (
			<Router>
				<div className={`app ${isMobile ? "mobile" : ""} ${ sidebar.collapsed ? "collapsed" : "expanded" }`}>
					<Header toggleSidebar={toggleSidebar} navi={navi} />
					<div className="container">
						<Sidebar sidebar={sidebar} onLoad={sidebarLoaded} hideSidebar={hideSidebar} navi={navi} />
						<main id="main">
							<Routes>
								<Route path="/robots.txt" element={null} />
								<Route path="/" element={<Front navi={navi} />} />
								<Route path="/profile" element={<Profile isMobile={isMobile} navi={navi} />} />
								<Route path="/academy" element={<Lost />} />
								<Route path="/popular" element={<Lost />} />
								<Route path="/popular/forum/:domain?" element={<Forum isMobile={isMobile} />} />
								<Route path="/popular/news/:domain?" element={<News isMobile={isMobile} />} />
								<Route path="/popular/youtube" element={<Youtube isMobile={isMobile} />} />
								<Route path="/popular/twitter/:type?" element={<Twitter isMobile={isMobile} />} />
								<Route path="/community" element={<Lost />} />
								<Route path="/insight" element={<Lost />} />
								<Route path="/insight/coin/:p?" element={<Coin isMobile={isMobile} />} />
								<Route path="/insight/exchange/:type?" element={<Exchange isMobile={isMobile} />} />
								<Route path="/insight/dominance" element={<Chart isMobile={isMobile} />} />
								<Route path="/insight/future/:type?" element={<Future isMobile={isMobile} />} />
								<Route path="/insight/kimp/:exchange?" element={<Kimp isMobile={isMobile} />} />
								<Route path="/insight/position" element={<Position isMobile={isMobile} />} />
								<Route path="/insight/whale" element={<Whale isMobile={isMobile} />} />
								<Route path="/notice" element={<Notice isMobile={isMobile} />} />
								<Route path="/lounge/:p?" element={<Lounge isMobile={isMobile} />} />
								<Route path="/lounge/:id/:title?" element={<LoungeView isMobile={isMobile} navi={navi} />} />
								<Route path="/meme/:p?" element={<Meme isMobile={isMobile} />} />
								<Route path="/meme/:id/:title?" element={<MemeView isMobile={isMobile} navi={navi} />} />
								<Route path="/flex/:p?" element={<Flex isMobile={isMobile} />} />
								<Route path="/flex/:id/:title?" element={<FlexView isMobile={isMobile} navi={navi} />} />
								<Route path="/guru/:p?" element={<Guru isMobile={isMobile} />} />
								<Route path="/guru/:id/:title?" element={<GuruView isMobile={isMobile} navi={navi} />} />
								<Route path="/etc" element={<Lost />} />
								<Route path="/etc/keyword" element={<Keyword isMobile={isMobile} />} />
								<Route path="/activate/:token" element={<Profile isMobile={isMobile} type="activate" navi={navi} />} />
								<Route path="/about" element={<Company navi={navi}  />} />
								<Route path="/policies" element={<Policies navi={navi} />} />
								<Route path="/privacy" element={<Privacy navi={navi} />} />
								<Route path="/tier" element={<Tier navi={navi} />} />

								<Route path="/test" element={<Test />} />
								<Route path="/dash/:type?" element={<Dash isMobile={isMobile} navi={navi} />} />
								<Route path="*" element={<Lost />} />
							</Routes>
							<Footer isMobile={isMobile} />
						</main>
						<aside id="aside">
							<div className="container">
									{ /* <Chat /> */ }
							</div>
						</aside>
					</div>
				</div>
				<div id="off-canvas">
					<HeadUp />
					<SignIn isMobile={isMobile} />
					<SignUp isMobile={isMobile} />
				</div>
			</Router>
	);
};

export default App;
