export const ellipsis = (str, limit=60, mark="…") => {
	if (!str) str="";
	const len = str.length;
	if (len > limit) {
		return str.substring(0, limit - mark.length) + mark;
	}
	return str;
}

export const ago=(t, stylized=true)=>{
	let time;

	// 날짜 처리
	if (typeof t === "string" && /^\d{4}-\d{2}-\d{2}([\d\s:]+)?$/.test(t)) {
		time = new Date(t);
	} else if (typeof t === "number") {
		time = new Date(t * 1000);
	} else {
		throw new Error("Invalid DateTime format");
	}

	const now = new Date();
	const diffInSeconds = Math.floor((now - time) / 1000);
	const diff = {
		y: Math.floor(diffInSeconds / (60 * 60 * 24 * 365)),
		m: Math.floor(diffInSeconds / (60 * 60 * 24 * 30)) % 12,
		d: Math.floor(diffInSeconds / (60 * 60 * 24)) % 30,
		h: Math.floor(diffInSeconds / (60 * 60)) % 24,
		i: Math.floor(diffInSeconds / 60) % 60,
		s: diffInSeconds % 60,
	};

	const render = (value, unit) => value > 0 ? (<> {value}<small>{unit}</small>{""}</>) : null;
	if (diff.y > 0) return stylized ? (<>{render(diff.y, "년")}{render(diff.m, "개월")}</>) : `${diff.y}년${diff.m > 0 ? ` ${diff.m}개월` : ""}`;
	if (diff.m > 0) {
		const weeks = Math.floor(diff.d / 7);
		const days = diff.d % 7;
		const rest = (weeks===0)? days+"일":weeks+"주";
		return stylized ? (<>{render(diff.m, "개월")}{weeks>0?render(weeks, "주"):render(days, "일")}</>) : `${diff.m}개월${rest}`;
	}
	if (diff.d >= 7) {
		const weeks = Math.floor(diff.d / 7);
		const days = diff.d % 7;
		return stylized ? (<>{render(weeks, "주")}{render(days, "일")}</>) : `${weeks}주${days > 0 ? ` ${days}일` : ""}`;
	}
	if (diff.d > 0) { return stylized ? (<>{render(diff.d, "일")}{render(diff.h, "시간")}</>) : `${diff.d}일${diff.h > 0 ? ` ${diff.h}시간` : ""}`; }
	if (diff.h > 0) { return stylized ? (<>{render(diff.h, "시간")}{render(diff.i, "분")}</>) : `${diff.h}시간${diff.i > 0 ? ` ${diff.i}분` : ""}`; }
	if (diff.i > 0) { return stylized ? (<>{render(diff.i, "분")}{render(diff.s, "초")}</>) : `${diff.i}분${diff.s > 0 ? ` ${diff.s}초` : ""}`; }
	if (diff.s < 3) { return stylized ? <span className="text-muted">방금</span> : "방금"; }
	return stylized ? render(diff.s, "초") : `${diff.s}초`;
}

export const formatNumber = (number, fixed=2, padZero=false) => {
	const formatSymbols = { decimalSeparator: ",", centsSeparator: "." };
	let numStr = String(number).replace(/,/g, "");
	let [integerPart, fractionalPart] = numStr.split(".") || [numStr, ""];
	if (integerPart === "" && fractionalPart) {
		integerPart = "0";
	}
	if (fractionalPart) {
		let significantDigits = fractionalPart.match(new RegExp(`(0*[1-9][0-9]{0,${fixed - 1}})`));
		if (significantDigits) {
			let digitsToKeep = significantDigits[1].length;
			let factor = Math.pow(10, digitsToKeep);
			let roundedValue = Math.round(number * factor) / factor;
			fractionalPart = roundedValue.toString().split(".")[1] || "";
		}
	}

	let formattedInteger = integerPart;
	const regex = /(\d+)(\d{3})/;
	while (regex.test(formattedInteger)) {
		formattedInteger = formattedInteger.replace(regex, `$1${formatSymbols.decimalSeparator}$2`);
	}
	const formattedNumber = fractionalPart
		? `${formattedInteger}${formatSymbols.centsSeparator}${fractionalPart}`
		: formattedInteger;

	return padZero && formattedNumber === "" ? "0" : formattedNumber;
};

export const bytes = (bytes, styled=false, precision=2) => {
	if (bytes === 0) return styled ? <span className="bytes">0<small className="text-muted">B</small></span> : "0B";
	const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const factor = Math.floor(Math.log(bytes) / Math.log(1024));
	const formattedBytes = (bytes / Math.pow(1024, factor)).toFixed(precision).replace(/\.?0+$/, ""); // Remove unnecessary zeros
	const unit = units[factor] || "";
  return styled ? (
		<span className="bytes">{formattedBytes}<small className="text-muted">{unit}</small></span>
	) : `${formattedBytes}${unit}`;
}


export const loadCss = (href, onLoad=null, onError=null) => {
	const css=document.createElement("link");
	css.rel="stylesheet";
	css.href=href;
	css.onload=()=>{if (onLoad) onLoad();};
	css.onerror=()=>{if (onError) onError();};
	document.head.appendChild(css);
	return () => {if (css) document.head.removeChild(css);};
};

export const avatar = (factor, options={}) => {
  const size=options.size && Number.isFinite(options.size) ? options.size : 32;
  let icon = null;
  if (factor?.t) {
    const text = size >= 30 
      ? factor.t.slice(0, 2).toUpperCase() // 두 글자
      : factor.t.slice(0, 1).toUpperCase(); // 한 글자
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill={factor.b} />
        <text x="50" y="50" dominantBaseline="central" textAnchor="middle" fontSize="280%" fontFamily="oh" fontWeight="bold" fill={factor.f}>{text}</text>
      </svg>
    );
  } else if (factor?.i) {
    icon = (<span className="avatar"><img src={factor.i} style={{width:size,height:size}} alt="" /></span>);
  }
  return icon;
};
