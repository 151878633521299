// component/Header.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../theme";
import { MobileContext } from "../mobile";
import { Search } from "./search";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import { Me } from "./me";

const Header = ({ toggleSidebar, navi }) => {
	const { isMobile } = useContext(MobileContext);
	const { theme, setTheme } = useContext(ThemeContext);
	const changeTheme = (selected) => { setTheme(selected); };
	const nav= (fn) => {
    const e=document.querySelector(".modal.show");
		if (e) {
      const modal=Modal.getInstance(e);
			if (modal) modal.hide();
    }; 
		fn();
  };

	return (
		<header id="header">
			<nav id="masthead" className="navbar navbar-expand">
				{ isMobile ? (
				<div className="container-fluid">
					<Link className="navbar-brand" to="/" onClick={()=>{nav(()=>navi("/"))}}><img src="https://s.cogle.io/i/logo.png" alt="cogle.io" /></Link>
					<button type="button" id="toggle-sidebar" onClick={()=>{nav(toggleSidebar)}}><span className="burger"></span></button>
				</div>) : (
				<div className="container-fluid">
					<button type="button" id="toggle-sidebar" onClick={()=>{nav(toggleSidebar)}}><span className="burger"></span></button>
					<Link className="navbar-brand" to="/" onClick={()=>{nav(()=>navi("/"))}}><img src="https://s.cogle.io/i/logo.png" alt="cogle.io" /></Link>
					<div className="navbar-collapse">
						<ul className="navbar-nav">
							<li className="nav-item"><Me className="nav-link" isMobile={isMobile} navi={navi} /></li>
							<li className="nav-item"><button className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><span className={`theme-icon ${theme}`}></span>Theme</button>
								<ul className="dropdown-menu dropdown-menu-end theme-switcher">
									<li><button className={`dropdown-item${theme==="light"?" on":""}`} onClick={() => changeTheme("light")}><span className="theme-icon light"></span>라이트</button></li>
									<li><button className={`dropdown-item${theme==="dark"?" on":""}`} onClick={() => changeTheme("dark")}><span className="theme-icon dark"></span>다크</button></li>
									<li><button className={`dropdown-item${theme==="system"?" on":""}`} onClick={() => changeTheme("system")}><span className="theme-icon system"></span>시스템</button></li>
								</ul>
							</li>
							<li className="nav-item">
								<Search expanded={false} />
							</li>
						</ul>
					</div>
				</div>
				)}
			</nav>
		</header>
	);
};

export default Header;
