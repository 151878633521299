import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, avatar, loadCss } from "../site";
import { useAuth } from "../auth";
import useMore from "../more";
import { More } from "../component/pagination";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";

import Add from "../component/add-post";
//import Update from "../component/flex-update";

import IconAdd from "@mui/icons-material/EditNote";

const Flex = ({ isMobile }) => {
  const { auth } = useAuth();
  const [stylized, stylize] = useState(false);
  const loaded = useRef(false);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  const fetchData = async (page) => {
    const response = await axios.get(`${api}/flex/`);
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return { list: response.data.data??[], more: response.data.more };
  };

  const { data, loadMore, hasMore, loading, reset, error } = useMore(fetchData);
	const onClose = (changed=true) => {
		if (changed) {
			loaded.current = false;
			reset();
			loadMore();
		}
		const modal = document.querySelector(".modal.show");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	}

  useEffect(() => {
    return loadCss("/css/showoff.css", () => stylize(true));
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadMore();
    }
  }, [loadMore]);

  if (!stylized) return null;

  return (
    <section className={`post showoff${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 수익 인증</title>
      </Helmet>
			<h1>수익 인증 {auth?.id > 0 && (<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-flex"><IconAdd style={{fontSize:"1.1rem"}} />짤 올리기</button>)}</h1>
      {data?.length > 0 ? (
				<ul className="container flex">
					{data.map((e,n)=>(
					<li key={e.id} className="col-sm-12 col-md-3">
						<article className="hentry">
							<Link to={`/flex/${e.endpoint}`} className="card shadow-3d">
								<div className="card-body cover">
									<figure><img src={e.thumbnail} alt="" /></figure>
									<div className="avatar">{avatar(e.avatar,{size:48})}</div>
								</div>
								<div className="card-footer">
									<div className="entry-title">{e.title}</div>
									<div className="author text-end">
										<time className="entry-date" dateTime={e.created_at}>{ago(e.created_at)}</time>
										<span className="author">{e.nick}</span>
										<small className="text-muted"><i className="md-icon visibility"></i>{e.hit.toLocaleString()}, <i className="md-icon forum"></i>{e.comments.toLocaleString()}</small>
									</div>
								</div>
							</Link>
						</article>
					</li>
					))}
				</ul>
      ) :  loading||(<div className="empty"><div className="alert">아직 게시글이 없습니다</div></div>)}

      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>loadMore()} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading…"}} />}
      {!hasMore && !error && <></>}
			<Add isMobile={isMobile} forum="flex" onClose={onClose} auth={auth} />
    </section>
  );
};

export default Flex;
