import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { bytes, avatar, loadCss } from "../site";
import { useAuth } from "../auth";
import { Modal, Collapse } from "bootstrap/dist/js/bootstrap.bundle.min";

//import Add from "../component/lounge-add";
//import Update from "../component/lounge-update";

import Comment from "../component/comment";
import Add from "../component/add-post";
import Update from "../component/update-post";

import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import IconAdd from "@mui/icons-material/EditNote";
import IconDel from "@mui/icons-material/Delete";
import IconDown from "@mui/icons-material/Download";
import IconAt from "@mui/icons-material/AccessTime";
import IconAttach from "@mui/icons-material/AttachFile";
import IconBookmark from "@mui/icons-material/PlaylistAdd";


import IconEdit from "@mui/icons-material/Edit";
import ThumbDown from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUp from '@mui/icons-material/ThumbUpOffAlt';

const LoungeView = ({ isMobile, navi }) => {
  const navigate = useNavigate();
  const { id } = useParams();
	const { auth } = useAuth();
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [reaction, setReaction] = useState({like:0,dislike:0});
  const api = process.env.REACT_APP_API;
  const loading = useRef(true);
	axios.defaults.withCredentials = true;

  const fetchData = useCallback(async () => {
    try {
      const response=await axios.get(`${api}/lounge/view`,{params:{id}});
      const r=response.data;
			//console.log(r);
      setData(r.data||{});
			setReaction({like:r.data.likes??0,dislike:r.data.dislikes??0});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      loading.current=false;
    }
  }, [api, id]);

	const like = useCallback(async (t,i) => {
    const formData = new FormData();
    formData.append("member_id", auth?.id ?? 0);
		formData.append("payload",btoa(JSON.stringify({n:"lounge",t,i})));
		formData.append("reply",false);
    try {
      const response=await axios.post(`${api}/react/`,formData);
      const r=response.data;
			console.log(r);
      if (r.error) throw new Error(r.error);
			if (r.reaction) setReaction(r.reaction);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
	}, [api, auth?.id ]);

	const content = (e) => {
    if (!e) return null;

    const rawContent = JSON.parse(e);
    const contentState = convertFromRaw(rawContent);
    const html = stateToHTML(contentState);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

	const attachment = (e) => {
    if (e.length === 0) return null;
    return (
      <ul className="attachment">
				<li><h4><IconAttach style={{fontSize:"1.1rem"}} />첨부파일</h4></li>
        {e.map((file, index) => (
          <li key={index} className="file">
						<a href={file.path} download={file.name} target="_blank" rel="noopener noreferrer" ><IconDown style={{fontSize:"1.2rem"}}/><span className="ellipsis">{file.name}</span><small className="size">{bytes(file.size)}</small></a>
          </li>
        ))}
      </ul>
    );
  };

	const remove = async (id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("member_id", auth?.id ?? 0);
    try {
      const response = await axios.post(`${api}/lounge/remove`, formData);
			console.log(response.data);
      if (response.data.error) throw new Error(response.data.error);
			onClose(true);
			//loaded.current = false;
			//reset();
			//loadMore();
    } catch (error) {
      console.error("공지사항 수정 중 오류:", error);
      alert("오류가 발생했습니다.");
    }
	}

	const onClose = (changed=true) => {
    const e=document.querySelector(".modal.show");
		if (e) {
      const modal=Modal.getInstance(e);
			if (modal) modal.hide();
    }; 
		if (changed) navigate("/lounge");
	}

	const update = (changed=true) => {
		onClose(false);
		fetchData();
	}

	const hideCollapse = (e) => {
		const c = e.target.closest('.collapse');
		if (c) {
			const i = Collapse.getInstance(c) || new Collapse(c);
			i.hide();
		}
	}

  useEffect(() => {
		if (loading.current) {
			loading.current=false;
			fetchData();
		}
  }, [fetchData]);

  useEffect(() => {
    return loadCss("/css/lounge.css", () => stylize(true));
  }, []);

  if (!stylized) return null;
	if (!data.id) return null;

  return (
    <section className={`post lounge${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 자유게시판</title>
      </Helmet>
			<h1>자유게시판 {auth?.id > 0 && (<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-lounge"><IconAdd style={{fontSize:"1.1rem"}} />새 글쓰기</button>)}</h1>
			<article className="hentry card">
				<div className="card-header">
					<h3 className="entry-title">{data.title}</h3>
					<ul className="feedback">
						<li>
							<ul className="entry-meta">
								<li>{avatar(data.avatar,{size:48})}</li>
								<li>
									<span className="author fn">{data.nick}</span>
									<span className="published"><IconAt style={{fontSize:"1rem"}} /><time dateTime={data.created_at}>{data.created_at}</time></span>
								</li>
							</ul>
						</li>
						<li>
							<ul className="navbar">
								<li>
									{ auth?.id > 0 ? (
									<div className="input-group">
										<button type="button" className="btn btn-light" onClick={()=>like("like",data.id)}><ThumbUp /> {reaction.like.toLocaleString()} </button>
										<button type="button" className="btn btn-light" onClick={()=>like("dislike",data.id)}><ThumbDown /> {reaction.dislike.toLocaleString()} </button>
									</div>
									) : (
									<div className="input-group">
										<button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#sign-in"><ThumbUp /> {reaction.like.toLocaleString()} </button>
										<button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#sign-in"><ThumbDown /> {reaction.dislike.toLocaleString()} </button>
									</div>
									)}
								</li>
								<li><button type="button" className="btn btn-light"><IconBookmark /></button></li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="card-body">
					<div className="entry-content">{content(data.content)}</div>
				</div>
				<div className="card-footer">
					{attachment(data.attachment??[])}
					{auth?.id === data.member_id && (
					<div className="update">
						<div className="input-group">
							<button type="button" className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#update-lounge"><IconEdit style={{fontSize:"1.1rem"}} />수정</button>
							<button type="button" className="btn btn-sm btn-danger" data-bs-toggle="collapse" data-bs-target={`#remove-${data.id}`}><IconDel style={{fontSize:"1.1rem"}} />지우기</button>
						</div>
						<div id={`remove-${data.id}`} className="collapse">
							<div>완전히 삭제됩니다. 지우시겠습니까?</div>
							<div className="input-group">
								<button type="button" className="btn btn-sm btn-secondary" onClick={hideCollapse} >취소</button>
								<button type="button" className="btn btn-sm btn-danger" onClick={()=>remove(data.id)}>지우기</button>
							</div>
						</div>
					</div>
					)}
				</div>
			</article>
			<Comment auth={auth} payload={{t:"lounge",i:data.id,p:0}} />
			<Add isMobile={isMobile} forum="lounge" onClose={onClose} auth={auth} />
			<Update isMobile={isMobile} forum="lounge" onClose={update} data={data} auth={auth} />
    </section>
  );
};

export default LoungeView;
