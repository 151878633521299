import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import axios from "axios";
import { bytes,ago } from "../../site";

import IconDown from "@mui/icons-material/Download";
import IconUpload from "@mui/icons-material/UploadFileTwoTone";
import IconDelete from "@mui/icons-material/ClearTwoTone";

const Stash = ({ isMobile }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { auth } = useAuth();
  const api = process.env.REACT_APP_API;
  axios.defaults.withCredentials = true;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/dash/stash`, {
          headers: { Authorization: `Bearer ${auth?.profile?.token}` },
        });
        console.log(response.data);
        if (response.data.error) throw new Error(response.data.error);
        setData(response.data.list);
        setError(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(true);
      }
    };
    fetchData();
  }, [api, auth]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const upload = async () => {
    if (!selectedFile) {
      setError("파일을 선택해주세요.");
      return;
    }

    const payload = new FormData();
    payload.append("file", selectedFile);

    try {
      setUploading(true);
      const response = await axios.post(`${api}/dash/upload`, payload, {
        headers: {"Content-Type": "multipart/form-data",Authorization: `Bearer ${auth?.profile?.token}` },
      });
      console.log("Upload success:", response.data);
      setData((prevData) => [response.data.file, ...prevData]);
      setSelectedFile(null);
    } catch (err) {
      console.error("Error uploading file:", err);
      setError("파일 업로드에 실패했습니다.");
    } finally {
      setUploading(false);
    }
  };

  const remove = async (name,event) => {
    const payload = new FormData();
    payload.append("file", name);
    try {
      const response = await axios.post(`${api}/dash/remove-file`, payload, {
        headers: {"Content-Type": "multipart/form-data",Authorization: `Bearer ${auth?.profile?.token}` },
      });
			event.target.closest("li.file").remove();
      console.log("삭제되었습니다", response.data);
    } catch (err) {
      console.error("Error :", err);
      setError("파일 삭제 실패했습니다.");
    } 
  };


  return (
    <section className={`stash ${isMobile ? " mobile" : ""}`}>
      <h6>임시 저장 파일</h6>
      <div className="alert alert-warning">
        * 최대 32Mb까지 업로드 가능
        <input type="file" onChange={handleFileChange} style={{ display: "block", margin: "10px 0" }} />
        <button type="button" className="btn btn-sm btn-primary" onClick={upload} disabled={uploading} >
          <IconUpload style={{ fontSize: "1.2rem" }} /> {uploading ? "업로드 중..." : "파일 올리기"}
        </button>
				{error && (<p className="text-danger mt-2">{error}</p>)}
      </div>
      <ul className="list-group list-group-flush">
        {data.map((file, n) => (
          <li key={n} className="file">
					{file.type && file.type.startsWith("image/") && (
						<img src={file.path} className="preview" alt="" />
					)}
            <a href={file.path} download={file.name} target="_blank" rel="noopener noreferrer" >
              <IconDown style={{ fontSize: "1.2rem" }} />
              <span className="ellipsis">{file.name}</span>
              <small className="size">{bytes(file.size)}</small>
              <time className="at" dateTime={file.at}>{ago(file.at)}<small>전</small></time>
            </a>
						<button className="btn btn-sm btn-danger" onClick={(e)=>remove(file.name,e)}><IconDelete style={{fontSize:"1rem"}} /></button>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Stash;
