import { useState, useCallback } from "react";

const useMore = (fetchData) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadMore = useCallback(async () => {
    if (loading || !hasMore) return; // 중복 호출 방지
    setLoading(true);
    try {
      //console.log("page => ", page);
      const { list, more } = await fetchData(page);
      setData((prevData) => [...prevData, ...list]);
      setHasMore(more);
      setPage((prevPage) => prevPage + 1);
    } catch (e) {
      console.error("Error loading data:", e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [fetchData, page, hasMore, loading]);

  const reset = () => {
    setPage(1);
    setData([]);
    setError(false);
    setHasMore(true);
		setLoading(false);
  };
  //const clearError = useCallback(() => setError(false), []);
  return { data, loadMore, hasMore, loading, reset, error };
};

export default useMore;
