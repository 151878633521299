import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { More } from "../../component/pagination";
import axios from "axios";
import { loadCss } from "../../site";

const Card = React.memo(({ symbol, item, refs }) => (
  <div className="card">
    <h3 className="card-header">
      <span className="crypto coin" data-symbol={symbol.toLowerCase()}>
        {symbol}
      </span>
    </h3>
    <ul className="card-body">
      {Object.entries(item).map(([x, value]) => (
        <li key={`${symbol}-${x}`} className="delta">
          <span className="crypto" data-exchange={x.toLowerCase()}>{x}</span>
          <span ref={(el) => { if (el) refs.current[`${symbol}-${x}`] = el; }} data-delta={isNaN(parseFloat(value)) ? "-" : parseFloat(value)} >{isNaN(parseFloat(value)) ? "-" : value}</span>
        </li>
      ))}
    </ul>
  </div>
));

const Table = React.memo(({ data, exchange, refs }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Symbol</th>
        {exchange.map((ex) => (
          <th key={ex}><span className="crypto" data-exchange={ex.toLowerCase()}><br />{ex}</span></th>
        ))}
      </tr>
    </thead>
    <tbody>
      {Object.entries(data).map(([symbol, item]) => (
        <tr key={symbol}>
          <td>
            <strong className="crypto coin" data-symbol={symbol.toLowerCase()}>
              {symbol}
            </strong>
          </td>
          {Object.entries(item).map(([x, value]) => (
            <td key={`${symbol}-${x}`} className="delta">
              <span ref={(el) => { if (el) refs.current[`${symbol}-${x}`] = el; }} data-delta={isNaN(parseFloat(value)) ? "-" : parseFloat(value)} >{isNaN(parseFloat(value)) ? "-" : value}</span>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
));

const Future = ({ isMobile }) => {
  const { type } = useParams();
  const [stylized, stylize] = useState(false);
  const [data, setData] = useState({});
  const [exchange, setExchange] = useState([]);
  const [lastAt, setLastAt] = useState(null);
  const [limit, setLimit] = useState(20); // limit 상태로 전체/부분 데이터 관리

  const tabRef = useRef(type || "usdt");
  const refs = useRef({}); // DOM 참조 관리
  const intervalRef = useRef(null); // Interval 관리
  const api = process.env.REACT_APP_API;

  const triggerFlash = (element, deltaClass) => {
    element.classList.add("flash", deltaClass);
    setTimeout(() => {
      element.classList.remove("flash", deltaClass);
    }, 2000); // Flash duration matches CSS
  };

  const updateValues = useCallback((newData) => {
    Object.entries(newData).forEach(([symbol, items]) => {
      Object.entries(items).forEach(([x, value]) => {
        const refKey = `${symbol}-${x}`;
        const element = refs.current[refKey];
        if (element) {
          const currValue = isNaN(parseFloat(value)) ? "-" : parseFloat(value);
          const lastValue = isNaN(parseFloat(element.dataset.delta))
            ? "-"
            : parseFloat(element.dataset.delta);

          if (currValue !== lastValue) {
            const deltaClass =
              currValue > lastValue ? "positive" : currValue < lastValue ? "negative" : "neutral";

            // Update value and dataset
            element.textContent = currValue === "-" ? "-" : currValue.toFixed(2);
            element.dataset.delta = currValue;

            // Add flash effect
            if (currValue !== "-") {
              triggerFlash(element, deltaClass);
            }
          }
        }
      });
    });
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${api}/insight/future`, {
        params: {
          type: tabRef.current,
          limit: limit, // limit 값에 따라 전체 또는 일부 데이터 로드
        },
      });
      const { rate, exchange, at } = response.data;
			console.log(Object.keys(rate));
      updateValues(rate || {});
      setData(rate || {});
      setExchange(exchange || []);
      setLastAt(at);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    intervalRef.current = setInterval(() => {
      fetchData();
    }, 5000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current); // Interval 정리
    };
  }, [limit]);

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);

  const switchTab = async (newTab) => {
    tabRef.current = newTab;
    setLimit(20);
    await fetchData(); // 새로운 탭 데이터 로드
  };

  const loadMore = async () => {
    setLimit(null);
    await fetchData();
  };

  if (!stylized) return null;

  return (
    <section className={`insight future${isMobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 펀딩비율</title>
        <meta name="description" content="Check the latest funding rates on various exchanges." />
        <meta name="keywords" content="future, funding rate, crypto, usdt, token" />
      </Helmet>
      <h1>펀딩비율</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item"><button className={`nav-link${tabRef.current === "usdt" ? " active" : ""}`} onClick={() => switchTab("usdt")}>USDT</button></li>
        <li className="nav-item"><button className={`nav-link${tabRef.current === "token" ? " active" : ""}`} onClick={() => switchTab("token")}>TOKEN</button></li>
        <li className="at">
          <span>Last Updated: </span>
          {lastAt ? new Date(lastAt * 1000).toLocaleString() : "Loading…"}
        </li>
      </ul>

      {isMobile ? (
        <div>
          {Object.keys(data).length > 0 ? (
            Object.entries(data).map(([symbol, item]) => (
              <Card key={symbol} symbol={symbol} item={item} refs={refs} />
            ))
          ) : (
            <p>No data available.</p>
          )}
        </div>
      ) : (
        <div>
          {Object.keys(data).length > 0 ? (
            <Table data={data} exchange={exchange} refs={refs} />
          ) : (
            <p>No data available.</p>
          )}
        </div>
      )}

      {limit === 20 && <More load={loadMore} options={{ text: "더 보기" }} />}
    </section>
  );
};

export default Future;
