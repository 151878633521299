import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, avatar, loadCss } from "../site";
import { useAuth } from "../auth";
import useMore from "../more";
import { More } from "../component/pagination";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";

import Add from "../component/add-post";
//import Update from "../component/guru-update";

import IconAdd from "@mui/icons-material/EditNote";

const Guru = ({ isMobile }) => {
  const { auth } = useAuth();
  const [stylized, stylize] = useState(false);
  const loaded = useRef(false);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  const fetchData = async (page) => {
    const response = await axios.get(`${api}/guru/`);
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return { list: response.data.data??[], more: response.data.more };
  };

  const { data, loadMore, hasMore, loading, reset, error } = useMore(fetchData);
	const onClose = (changed=true) => {
		if (changed) {
			loaded.current = false;
			reset();
			loadMore();
		}
		const modal = document.querySelector(".modal.show");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	}

  useEffect(() => {
    return loadCss("/css/guru.css", () => stylize(true));
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadMore();
    }
  }, [loadMore]);

  if (!stylized) return null;

  return (
    <section className={`post guru${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 고수 라운지</title>
      </Helmet>
			<h1>고수 라운지 {auth?.id > 0 && (<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-guru"><IconAdd style={{fontSize:"1.1rem"}} />새 글쓰기</button>)}</h1>
      {data?.length > 0 ? (
				<ul className="container guru list-group list-group-flush">
					<li className="list-group-item">
						<div className="card">
							<div className="card-body"><span className="card-title">제목</span></div>
							<div className="card-footer">
								<ul><li>글쓴이</li><li>댓글</li><li>읽음</li><li>최근활동</li></ul>
							</div>
						</div>
					</li>
					{data.map((e,n)=>(
					<li key={e.id} className={`list-group-item ${n%2?" striped":""}`}>
						<article className="card">
							<Link to={`/guru/${e.endpoint}`} className="card-body">
								{e.thumbnail &&(<figure className={`thumbnail${e.thumbnail?"":" d-none d-md-block"}`}>{e.thumbnail&&(<img src={e.thumbnail} alt="" />)}</figure>)}
								<div className="card-title">{e.title}</div>
							</Link>
							<div className="card-footer">
								<ul><li data-nick={e.nick}>{avatar(e.avatar)}</li><li>{e.comments.toLocaleString()}</li><li>{e.hit.toLocaleString()}</li><li><time dateTime={e.updated_at}>{ago(e.updated_at)}</time></li></ul>
							</div>
						</article>
					</li>
					))}
				</ul>
      ) :  loading||(<div className="empty"><div className="alert">아직 게시글이 없습니다</div></div>)}

      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>loadMore()} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading…"}} />}
      {!hasMore && !error && <></>}
			<Add isMobile={isMobile} forum="guru" onClose={onClose} auth={auth} />
    </section>
  );
};

export default Guru;
