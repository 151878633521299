import React, { createContext, useContext, useState } from "react";

const api = process.env.REACT_APP_API;
const AuthContext = createContext();

export const Auth = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem("token");
		if (token) {
			const profile = JSON.parse(atob(token.split(".")[1]))?.profile;
			const isStaff = profile.is_staff??false;
			return { id:profile.id, profile, isStaff }
		}
    return {};
  });

  const login = async (payload, onSuccess, onError) => {
		let data=null;
    try {
      const response = await fetch(`${api}/member/sign-in`, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(payload)});
			//console.log(response.text());
      if (!response.ok) throw new Error("로그인 실패. 이메일 또는 비밀번호를 확인하세요.");
      data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			} else if (data.token) {
        const profile = JSON.parse(atob(data.token.split(".")[1])).profile; // JWT 디코딩
				console.log(profile);
        localStorage.setItem("token", data.token);
        //setAuth({token:data.token});
        setAuth({id:profile.id,profile:profile,isStaff:profile.is_staff??false});
				if (onSuccess) onSuccess();
      } else {
        throw new Error("서버에서 유효한 토큰을 받지 못했습니다.");
      }
    } catch (error) {
      //console.error("로그인 에러:", error);
			if (data.token) data = JSON.parse(atob(data.token.split(".")[1])); // JWT 디코딩
      if (onError) onError(data); // 에러 콜백 실행
    }
  };

  const signUp = async (payload, onSuccess, onError) => {
    try {
      const response = await fetch(`${api}/member/sign-up`, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(payload)});
			//console.log(response.text());
      if (!response.ok) throw new Error("회원가입 실패 했습니다");
      const data = await response.json();
			if (data.error) throw new Error(data.error);
			else if (data.token && data.add && data.sent) {
				console.log(data);
				if (onSuccess) onSuccess();
      } else if (data.debug) throw new Error(data.debug);
    } catch (error) {
      console.error("로그인 에러:", error);
      if (onError) onError(error.message); // 에러 콜백 실행
    }
		return null;
  };

  const by = (token, onSuccess) => {
		if (token) {
			try {
				const profile = JSON.parse(atob(token.split(".")[1])).profile; // JWT 디코딩
				localStorage.setItem("token", token);
				setAuth({id:profile.id,profile:profile,isStaff:profile.is_staff??false});
				if (onSuccess) onSuccess();
			} catch (e) {
				console.error("Error",e);
			}
		} 
  };

	const loginBy = (provider, isMobile, onClose) => {
		const url = `${api}/auth/by/${provider}`;

    const width = isMobile ? window.innerWidth : 500;
    const height = isMobile ? window.innerHeight : 600;
    const left = isMobile ? 0 : (window.screen.width / 2) - (width / 2);
    const top = isMobile ? 0 : (window.screen.height / 2) - (height / 2);
    const popup = window.open(url,"Social Login",`width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=yes,status=no`);

		if (!popup) {
      console.error("Popup blocked");
      return;
    }

    const handleMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_API) {
        console.error("Invalid origin");
        return;
      }
      const { error, token } = event.data;
      if (error) {
        console.error("Login failed");
      } else {
				by(token,onClose); // login 함수 호출
      }
      //if (popup) popup.close(); // Popup 창 닫기
      window.removeEventListener("message", handleMessage); // 리스너 정리
    };

    // 메시지 리스너 등록
    window.addEventListener("message", handleMessage);
	};

	const activate = (token) => {
		const profile = JSON.parse(atob(token.split(".")[1])).profile; // JWT 디코딩
		localStorage.setItem("token", token);
		//console.log("setAuth",token,profile);
		setAuth({profile:profile,isStaff:profile.is_staff??false});
	};

  const logout = () => {
    localStorage.removeItem("token");
    setAuth(null);
  };

  return (
    <AuthContext.Provider value={{ auth, signUp, login, loginBy, activate, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
