import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { loadCss } from "../../site";

const Position = ({ isMobile }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [stylized, stylize] = useState(false);
  const [loading, setLoading] = useState(true);
  const timerRef = useRef(null);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await axios.get(`${api}/insight/position`);
      //console.log(response.data);
      if (response.data.error) throw new Error(response.data.error);
      setData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      timerRef.current = setTimeout(fetchData, 60000);
    }
  }, [api]);

  useEffect(() => {
    fetchData();
    return () => { if (timerRef.current) clearTimeout(timerRef.current); };
  }, [fetchData]);

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);

  if (!stylized) return <div className="loading"><span className="loader">Loading…</span></div>;
  if (loading) return <div className="loading"><span className="loader">Loading…</span></div>;
  if (error) return <p>데이터를 불러오는 중 오류가 발생했습니다: {error}</p>;

  return (
    <section className={`insight position${isMobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 네임드 포지션</title>
      </Helmet>
      <h1>네임드 포지션</h1>
      <ul>
        {data?.length > 0 ? (
          data.map((item) => (
            <li
              key={item.id}
              data-position={
                item.position.position ? item.position.position : "off"
              }
            >
              <div className={`thumbnail ${item.position.position}`}>
                <a
                  href={`https://youtube.com/${
                    item.live ? `watch?v=${item.live}` : item.endpoint
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.thumbnail} alt={item.endpoint} />
                </a>
              </div>
              <div>
                <h4>
                  {item.title}
                  {/* item.live && (
                    <a
                      href={`https://youtube.com/watch?v=${item.live}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="badge"
                      data-live="yt"
                    >
                      실시간
                    </a>
                  ) */}
                </h4>
                <dl className="position">
                  <div>
                    <dt>{item.position.pair.replace(/\/.*$/, "")}</dt>
                    <dd className="capitalize">
                      {item.position.position === "na"
                        ? "-"
                        : item.position.position}
                    </dd>
                    {item.position.leverage && (
                      <dd className="x">{item.position.leverage.toLocaleString()}</dd>
                    )}
                  </div>
                  {item.other?.length > 0 && (
                    <>
                      {item.other.map((other, n) => (
                        <div key={`${item.id}${n}`}>
                          <dt>{other.pair.replace(/\/.*$/, "")}</dt>
                          <dd className="capitalize">{other.position}</dd>
                          {other.leverage && (
                            <dd className="x">{other.leverage.toLocaleString()}</dd>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </dl>
              </div>
            </li>
          ))
        ) : (
          <li>데이터가 없습니다.</li>
        )}
      </ul>
    </section>
  );
};

export default Position;
