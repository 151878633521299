import React, { useState } from "react";

import IconBack from '@mui/icons-material/UndoTwoTone';
//import IconView from '@mui/icons-material/PreviewTwoTone';
import IconView from '@mui/icons-material/Preview';
import IconSave from '@mui/icons-material/Save';
import IconCancel from '@mui/icons-material/Cancel';

const Editor = ({ content, onSave, onCancel }) => {
  const [value, setValue] = useState(content);
  const [isPreview, setIsPreview] = useState(false); // 미리보기 상태

  return (
    <>
      {isPreview ? (
        <>
					<div className="edit">
          <button className="btn btn-sm btn-secondary" onClick={() => setIsPreview(false)} ><IconBack style={{fontSize:"1.1rem"}}/>편집으로</button>
					</div>
          <div dangerouslySetInnerHTML={{ __html: value }} className="border p-3" />
        </>
      ) : (
        <>
          <div className="edit input-group">
            <button className="btn btn-sm btn-primary" onClick={() => setIsPreview(true)}><IconView style={{fontSize:"1.1rem"}}/>미리보기</button>
						<button className="btn btn-sm btn-success" onClick={() => onSave(value)}><IconSave style={{fontSize:"1.1rem"}}/>저장하기</button>
            <button className="btn btn-sm btn-secondary" onClick={onCancel}><IconCancel style={{fontSize:"1.1rem"}}/>취소</button>
          </div>
          {/* 편집 모드: textarea 표시 */}
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows="10"
            className="form-control"
          />
        </>
      )}
    </>
  );
};

export default Editor;
