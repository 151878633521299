import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const Contact = ({ isMobile }) => {
  const { token } = useParams();
	const [ error, setError ] = useState(false);
	const [ sent, setSent ] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { email, code } = location.state || {}; // 확인메일 재발송
	axios.defaults.withCredentials = true;

  return (
		<section className={`member ${isMobile?" mobile":""}`}>
		연락처
    </section>
  );
};
export default Contact;
