import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Vendor from "../../vendor";
import { ago, ellipsis, loadCss } from "../../site";
import useMore from "../../more";
import { More } from "../../component/pagination";

import IconAt from "@mui/icons-material/AccessTime";
import IconReply from "@mui/icons-material/Sms";
import IconView from "@mui/icons-material/Visibility";
import IconBookmark from "@mui/icons-material/PlaylistAdd";
import IconRight from "@mui/icons-material/ChevronRight";

const Forum = ({ isMobile }) => {
  const { domain } = useParams();
	const navigate = useNavigate();
  const [stylized, stylize] = useState(false);
  const domainRef = useRef(domain);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  const fetchData = async (page) => {
		if (loading) return;
		const params = domain ? { vendor:domain,page } : { page };
    const response = await axios.get(`${api}/popular/forum`,{ params });
    if (response.data.error) throw new Error("데이터 로드 실패");
		console.log(response.data);
    return {list: response.data.data, more: response.data.more};
  };

  const { data, loadMore, hasMore, loading, reset, error } = useMore(fetchData);

  useEffect(() => {
		return loadCss("/css/forum.css",()=>stylize(true));
  }, []);

  useEffect(() => {
    if (domain !== domainRef.current) {
      domainRef.current = domain;
      reset();
    }
  }, [domain, reset]);

  useEffect(() => {
    if (!loading && data.length === 0) loadMore();
  }, [loadMore, loading, data.length]);

  if (!stylized) return null;
  const vendor = Vendor.forum[domain] || { name: null };

  return (
    <section className={`popular forum${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 커뮤니티 인기글</title>
      </Helmet>
			<h1>커뮤니티 인기글 {vendor.name && <small><IconRight />{vendor.name}</small>}</h1>
      {data?.length > 0 && (
        <ul>
          {data.map((e,rank) => (
            <li key={`forum-${rank}`}>
              <article className="hentry card">
                <h3 className="card-header entry-title">
                  <a href={e.url} target="_blank" rel="noopener noreferrer"><span className="badge rank">{rank+1}</span>{e.title}</a>
                </h3>
								<div className="card-body">
									{(e.summary || e.thumbnail) && (
									<div className="entry-summary description">
                    <a href={e.url} target="_blank" rel="noopener noreferrer">{e.thumbnail && ( <img src={e.thumbnail} alt="" style={{ maxWidth: "160px" }} />)}
										{ellipsis(e.summary, isMobile ? 96 : 200)}</a>
									</div>
									)}
                  <div className="entry-content">
                    <ul>
                      {!domain && ( <li><span className="vendor">{e.vendor.name}</span></li>)}
                      <li><span className="author">{e.author}</span></li>
                      <li><IconAt /> <time dateTime={e.at}>{ago(e.at)}</time></li>
                      <li><IconReply /><span>{e.comment_.toLocaleString()}</span></li>
                      <li><IconView /><span>{e.hit_.toLocaleString()}</span></li>
                      <li><button type="button" className="btn bookmark"><IconBookmark /><small>저장</small></button>
                      </li>
                    </ul>
                  </div>
                </div>
              </article>
            </li>
          ))}
        </ul>
      )}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>loadMore()} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading…"}} />}
      {!hasMore && !error && <div>- 끝 -</div>}
    </section>
  );
};

export default Forum;
