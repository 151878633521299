import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { ago, bytes, loadCss } from "../site";
import { useAuth } from "../auth";
import useMore from "../more";
import { More } from "../component/pagination";
import { Modal, Collapse } from "bootstrap/dist/js/bootstrap.bundle.min";
//import Add from "../component/notice-add";
//import Update from "../component/notice-update";
import Add from "../component/add-post";
import Update from "../component/update-post";

import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

//import "draft-js/dist/Draft.css";

import IconAdd from "@mui/icons-material/EditNote";
import IconEdit from "@mui/icons-material/Edit";
import IconDel from "@mui/icons-material/Delete";
import IconDown from "@mui/icons-material/Download";

const Notice = ({ isMobile }) => {
  const { auth } = useAuth();
  const [stylized, stylize] = useState(false);
  const [notice, setNotice] = useState({});
  const loaded = useRef(false);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;


  const fetchData = async (page) => {
    const response = await axios.get(`${api}/notice/`);
		console.log(response.data);
    if (response.data.error) {
      throw new Error("데이터 로드 실패");
    }
    return { list: response.data.data??[], more: response.data.more };
  };

  const { data, loadMore, hasMore, loading, reset, error } = useMore(fetchData);

	const content = (content) => {
    if (!content) return null;
    const rawContent = JSON.parse(content);
    const contentState = convertFromRaw(rawContent);
    const html = stateToHTML(contentState);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

	const attachment = (e) => {
    if (e.length === 0) return null;
    return (
      <div>
        <h3>첨부파일</h3>
        <ul className="attachment">
          {e.map((file, index) => (
            <li key={index} className="file">
							<a href={file.path} download={file.name} target="_blank" rel="noopener noreferrer" ><IconDown className="text-primary" /><span className="ellipsis">{file.name}</span><small className="size">{bytes(file.size)}</small></a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

	const onClose = (changed=true) => {
		if (changed) {
			loaded.current = false;
			reset();
			loadMore();
		}
		const modal = document.querySelector(".modal.show");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	}

	const remove = async (id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("member_id", auth.id ?? 0);
    formData.append("is_staff", auth.isStaff ?? false);
    try {
      const response = await axios.post(`${api}/notice/remove`, formData);
      if (response.data.error) throw new Error(response.data.error);
			loaded.current = false;
			reset();
			loadMore();
    } catch (error) {
      console.error("공지사항 수정 중 오류:", error);
      alert("오류가 발생했습니다.");
    }
	}

	const hideCollapse = (e) => {
		const c = e.target.closest('.collapse');
		if (c) {
			const i = Collapse.getInstance(c) || new Collapse(c);
			i.hide();
		}
	}

  useEffect(() => {
    return loadCss("/css/notice.css", () => stylize(true));
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadMore();
    }
  }, [loadMore]);

  if (!stylized) return null;

  return (
    <section className={`post notice${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 공지사항</title>
      </Helmet>
			<h1>공지사항 {auth?.isStaff && (<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-notice"><IconAdd style={{fontSize:"1.1rem"}} />새 공지쓰기</button>)}</h1>
      {data?.length > 0 ? (
			<ul id="notice" className="list-group list-grup-flush accordion">
				{data.map((e)=>(
				<li key={`notice-${e.id}`}  className="list-group-item accordion-item">
					<article className="hentry">
						<h3 className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#notice-${e.id}`}><span>{e.title}</span><time dateTime={e.updated_at} className="text-muted">{ago(e.updated_at)}<small>전</small></time></h3>
						<div id={`notice-${e.id}`} className="collapse" data-bs-parent="#notice">
							<div className="content">
							{content(e.content)}
							{attachment(e.attachment??[])}
							</div>
							<div className="footer">
								<time dateTime={e.updated_at} className="text-muted">{e.updated_at}</time>
								{e.member_id===auth?.id && (
									<div>
										<div className="input-group">
											<button type="button" className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#update-notice" onClick={()=>setNotice(e)}><IconEdit style={{fontSize:"1.1rem"}} />수정</button>
											<button type="button" className="btn btn-sm btn-danger" data-bs-toggle="collapse" data-bs-target={`#remove-${e.id}`}><IconDel style={{fontSize:"1.1rem"}} />지우기</button>
										</div>
										<div id={`remove-${e.id}`} className="collapse">
											<div>완전히 삭제됩니다. 지우시겠습니까?</div>
											<div className="input-group">
												<button type="button" className="btn btn-sm btn-secondary" onClick={hideCollapse} >취소</button>
												<button type="button" className="btn btn-sm btn-danger" onClick={()=>remove(e.id)}>지우기</button>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</article>
				</li>
				))}
			</ul>
      ) : loading||(<div className="empty"><div className="alert">아직 공지사항이 없습니다</div></div>)}
      {error && <More load={()=>false} options={{text:"데이터를 불러오는 중 오류가 발생했습니다."}} />}
      {!loading && hasMore && (<More load={()=>loadMore()} options={{text:"더 보기"}} />)}
      {loading &&  <More load={()=>false} options={{text:"Loading…"}} />}
      {!hasMore && !error && <></>}
			<Add isMobile={isMobile} forum="notice" onClose={onClose} auth={auth} />
			<Update isMobile={isMobile} forum="notice" onClose={onClose} data={notice} auth={auth} />
    </section>
  );
};

export default Notice;
