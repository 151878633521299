import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Pagination } from "../../component/pagination";
import { formatNumber, loadCss } from "../../site";

const Coin = ({ isMobile }) => {
  const { p } = useParams();
  const page = p ? parseInt(p, 10) : 1;
  const navigate = useNavigate();

  const [data, setData] = useState([]); // 코인 데이터
  const [error, setError] = useState(null); // 에러 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [stylized, stylize] = useState(false); // CSS 로딩 상태
  const [total, setTotal] = useState({ count: 0, page: 0 }); // 전체 페이지 수
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  const fetchData = useCallback(async () => {
    setError(null); // 에러 초기화
    setLoading(true); // 로딩 시작
    try {
      const response = await axios.get(`${api}/insight/coin`, { params: { page } });
      if (response.data.error) throw new Error("데이터 로드 실패");

      setData(response.data.data);
      setTotal({ count: response.data.total, page: response.data.pages });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // 로딩 완료
    }
  }, [api, page]);

  const load = (n) => {
    const to = n > 1 ? `+${n}` : "";
    navigate(`/insight/coin/${to}`);
  };

  useEffect(() => {
    fetchData(); // 페이지 변경 시 실행
  }, [page, fetchData]);

  useEffect(() => {
    return loadCss("/css/insight.css", () => stylize(true));
  }, []);

  if (!stylized) return null;

  if (loading) {
    return (
      <div className="loading">
        <span className="loader">Loading…</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        데이터를 불러오는 중 오류가 발생했습니다: {error}
      </div>
    );
  }

  const List = ({ mobile, data }) => {
    if (mobile) {
      return (
        <ul className="mobile">
          {data.map((coin, rank) => (
            <li key={coin.symbol}>
              <div className="card" style={{ marginBottom: "0.25rem" }}>
                <h3 className="card-header">
                  <span className="rank badge">{(page - 1) * 100 + rank + 1}</span>
                  <strong
                    className="crypto coin"
                    data-symbol={coin.symbol.toLowerCase()}
                  >
                    {coin.name}
                  </strong>
                  <strong className="symbol">{coin.symbol}</strong>
                </h3>
                <dl className="card-body">
                  <div>
                    <dt>
                      가격 <small className="text-muted">＄</small>
                    </dt>
                    <dd>{formatNumber(coin.usd.price)}</dd>
                  </div>
                  <div>
                    <dt>유통량</dt>
                    <dd>{formatNumber(coin.supply)}</dd>
                  </div>
                </dl>
              </div>
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th scope="col" className="text-center">#</th>
              <th scope="col">이름</th>
              <th scope="col">심볼</th>
              <th scope="col" className="text-end">
                가격<small className="text-muted">＄</small>
              </th>
              <th scope="col" className="text-end">유통량</th>
              <th scope="col" className="text-end">
                시가총액<small className="text-muted">＄</small>
              </th>
              <th scope="col" className="text-end">
                역대최고가<small className="text-muted">＄</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((coin, rank) => (
              <tr key={`${coin.symbol}${rank}`}>
                <td className="text-center">
                  <small className="rank">{(page - 1) * 100 + rank + 1}</small>
                </td>
                <td>
                  <strong
                    className="crypto coin"
                    data-symbol={coin.symbol.toLowerCase()}
                  >
                    {coin.name}
                  </strong>
                </td>
                <td className="text-end">{coin.symbol}</td>
                <td className="text-end">{formatNumber(coin.usd.price)}</td>
                <td className="text-end">{formatNumber(coin.supply)}</td>
                <td className="text-end">{formatNumber(coin.usd.cap)}</td>
                <td className="text-end">{formatNumber(coin.ath)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <section className={`insight coin rank${isMobile ? " mobile" : ""}`}>
      <Helmet>
        <title>코글 - 코인순위</title>
      </Helmet>
      <h1>코인순위</h1>
      {data.length > 0 && <List mobile={isMobile} data={data} />}
      {!loading && (
        <Pagination
          load={load}
          page={page}
          total={total.page}
          options={{ prefix: "+", tab: isMobile ? 1 : 2 }}
        />
      )}
    </section>
  );
};

export default Coin;
