import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const Privacy = ({navi}) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  useEffect(() => {
		navi("/privacy");
		const fetchData = async () => {
			setLoading(true); // 로딩 시작
			try {
				const response = await axios.get(`${api}/about`, { params: { type:"privacy" } });
				if (response.data.error) throw new Error("데이터 로드 실패");
				console.log(response.data);
				setData(response.data.content);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false); // 로딩 완료
			}
		};
    fetchData();
  }, [api,navi]);


  if (loading) return (<div className="loading"><span className="loader">Loading…</span></div>);

	return (
		<section className="about privacy">
      <Helmet>
        <title>코글 - 개인 정보 보호</title>
      </Helmet>
      <h1>개인 정보 보호</h1>
			<div className="container" dangerouslySetInnerHTML={{ __html: data || "" }}></div>
		</section>
	);
};
export default Privacy;
