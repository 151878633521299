import React from "react";
import IconPrev from '@mui/icons-material/ArrowBackIosOutlined';
import IconNext from '@mui/icons-material/ArrowForwardIosOutlined';


export const Pagination = ({ page, total, load, options = {} }) => {
  const tab = options.tab || 2;
  const ellipsis = "…";

	const calculate = () => {
		const near = [];
		const tabCount = tab * 2 + 1;
		const edgeCount = 2;
		if (total <= tabCount) {
			for (let i = 1; i <= total; i++) { near.push(i); }
			return near;
		}
		for (let i = Math.max(page - tab, 1); i <= Math.min(page + tab, total); i++) { near.push(i); }
		while (near.length < tabCount && near[0] > 1) { near.unshift(near[0] - 1); }
		while (near.length < tabCount && near[near.length - 1] < total) { near.push(near[near.length - 1] + 1); }
		const left = near[0] > edgeCount + 1 ? [1, 2, ellipsis] : Array.from({ length: near[0] - 1 }, (_, i) => i + 1);
		const right = near[near.length - 1] < total - edgeCount
			? [ellipsis, total - 1, total]
			: Array.from({ length: total - near[near.length - 1] }, (_, i) => near[near.length - 1] + i + 1);
		return [...left, ...near, ...right];
	};

  const pagination = calculate();
  return (
    <nav>
			{total > 1 && (
      <ul className="pagination">
        <li className={`page-item prev${page===1?" disabled":""}`}>
          <button className="page-link" onClick={() => page > 1 && load(page - 1)} disabled={page === 1}><IconPrev /></button>
        </li>

        {pagination.map((p, index) => (
          <li key={index} className={`page-item${p===page?" active":""}`}>
            {typeof p === "number" ? (
              <button className="page-link" onClick={()=>load(p)} disabled={p===page}>{p}</button>
            ) : (
              <span className="ellipsis">{p}</span>
            )}
          </li>
        ))}

        <li className={`page-item next${page===total?" disabled":""}`}>
          <button className="page-link" onClick={() => page < total && load(page + 1)} disabled={page === total}><IconNext /></button>
        </li>
      </ul>
			)}
    </nav>
  );
};

export const More = ({ load, options = {} }) => {
  const text = options.text || "더 보기";
  const at = options.at ? `data-at=${options.at}` : "";
  return (
    <div className="pagination more">
      <hr />
      <div>
				<button type="button" onClick={(e) => { e.preventDefault(); load(); }} className="btn btn-light rounded-pill" {...(at && { [at.split("=")[0]]: at.split("=")[1] })} >
					<span>{text}</span>
					<span className="carot"></span>
        </button>
      </div>
    </div>
  );
};
