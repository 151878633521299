import React, { useContext } from "react";
import { Link } from "react-router-dom";
import IconC from '@mui/icons-material/Copyright';

const Footer = ({ isMobile }) => {
  const currentYear = new Date().getFullYear();

  const navLinks = (
    <ul className="about">
      <li><Link to="/about">회사소개</Link></li>
      <li><Link to="/policies">약관</Link></li>
      <li><Link to="/privacy">개인 정보 보호</Link></li>
      <li><Link to="/support">고객센터</Link></li>
    </ul>
  );

  return (
    <footer id="footer">
      <div className="container">
        <img className="logo" src="https://s.cogle.io/i/logo.png" alt="코글 로고" />
        {!isMobile && navLinks}
        <ul className="sns">
          <li><a href="https://facebook.com" className="facebook" target="_blank" rel="noopener noreferrer" aria-label="페이스북"></a></li>
          <li><a href="https://x.com" className="twitter" target="_blank" rel="noopener noreferrer" aria-label="트위터"></a></li>
          <li><a href="https://google.com" className="google" target="_blank" rel="noopener noreferrer" aria-label="구글"></a></li>
          <li><a href="https://kakao.com" className="kakao" target="_blank" rel="noopener noreferrer" aria-label="카카오"></a></li>
          <li><a href="https://naver.com" className="naver" target="_blank" rel="noopener noreferrer" aria-label="네이버"></a></li>
        </ul>
      </div>
      {isMobile && navLinks}
      <div className="copyright">
        Copyright <IconC style={{ fontSize: "1.2rem" }} /> 
        <time dateTime={currentYear}>{currentYear}</time>
        <span> All rights reserved.</span>
        {isMobile && (<br />)} 
        Made with <img src="https://s.cogle.io/i/heart.svg" className="heart" alt="사랑" /> by 
        <strong>코글<small>.io</small></strong>
      </div>
    </footer>
  );
};

export default Footer;
