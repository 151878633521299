import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { loadCss } from "../../site";

const Keyword = ({ sidebar }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [stylized, stylize] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

  useEffect(() => {
		return loadCss("/css/keyword.css",()=>stylize(true));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // 로딩 시작
      setError(null); // 에러 초기화
      try {
        const response = await axios.get(`${api}/etc/keyword`);
        if (response.data.error === false) {
          setData(response.data.data);
        } else {
          throw new Error("데이터 로드 실패"); // 에러 발생 시 명확히 처리
        }
      } catch (err) {
        setError(err.message); // 에러 메시지 설정
      } finally {
        setLoading(false); // 로딩 종료
      }
    };

    fetchData();
  }, [api]);

	if (!stylized) return null;
	if (loading) return <div className="loading"><span className="loader">Loading…</span></div>;
  if (error) return <p>데이터를 불러오는 중 오류가 발생했습니다: {error}</p>; // 에러 메시지 표시

  return (
    <section className="etc keyword">
			<Helmet>
        <title>코글 - 실시간 급상승 검색어</title>
      </Helmet>
      <h1>실시간 급상승 검색어</h1>
      <div className="container list">
        {Object.entries(data).map(([vendor, e]) => (
          <ul className="list-group list-group-flush" key={vendor}>
            <li className="list-group-item header"><img src={`https://s.cogle.io/i/vendor/${e.logo}`} alt={vendor} /></li>
            {e.terms.map((q, index) => {
              const endpoint = `${e.endpoint}${encodeURIComponent(q)}`;
              return (
                <li className="list-group-item" key={`${vendor}-${index}`}><a href={endpoint} target="_blank" rel="noopener noreferrer">{q}</a></li>
              );
            })}
          </ul>
        ))}
      </div>
    </section>
  );
};

export default Keyword;
