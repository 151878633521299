import React, { useState, useEffect } from "react";
import axios from "axios";
import { Fng, Alt, Dominance } from "../component/chart";
const Front = ({ sidebar, navi  }) => {

	const [chart, setChart] = useState([]);
	const [krw, setKrw] = useState(null);
	const [error, setError] = useState(null);
	const [loaded, setLoaded] = useState(false);
	axios.defaults.withCredentials = true;

  useEffect(() => {
		navi("/");
		const api = process.env.REACT_APP_API;
		const fetchData = async () => {
			setLoaded(false);
			setError(null);
			try {
				const [chartResponse, krwResponse] = await Promise.all([
					axios.get(`${api}/insight/chart`),
					axios.get(`${api}/currency`),
				]);
				if (!chartResponse.data.error) setChart(chartResponse.data.data);
				else throw new Error("차트 데이터 로드 실패");

        if (!krwResponse.data.error) setKrw(krwResponse.data.krw);
        else throw new Error("KRW 데이터 로드 실패");

			} catch (err) {
				setError(err.message);
			} finally {
				setLoaded(true);
			}
		};
    fetchData();
  }, [navi]);

  if (error) return <div className="alert alert-danger">데이터를 불러오는 중 오류가 발생했습니다: {error}</div>;
	if (!loaded) return null;

	return (
		<section className="home">
			<div className="container">
				<h1>환영합니다</h1>
				<div className="lead">
					<p>가상화폐와 관련된 모든 것!</p>
					<p>뉴스, 토론, 차트, 실시간 정보, 그리고 열정적인 투자자들까지 한곳에서 만나보세요</p>
				</div>
			</div>
			{chart.fng&&(
			<div className="card mt-4">
				<h3 className="card-header">공포 탐욕 지수</h3>
				<div className="card-body"><Fng data={chart.fng} /></div>
			</div>
			)}
			{chart.alt&&(
			<div className="card mt-4">
				<h3 className="card-header">알트코인 시즌</h3>
				<div className="card-body"><Alt value={chart.alt} /></div>
			</div>
			)}
			{chart.btc&&(
			<div className="card mt-4">
				<h3 className="card-header">비트코인 도미넌스</h3>
				<div className="card-body"><Dominance data={chart.btc} /></div>
			</div>
			)}

			{krw && (
			<div className="card mt-4">
				<h3 className="card-header">환율(KRW)</h3>
				<ul className="card-body currency">
					{Object.entries(krw).map(([key,value])=>(
					<li key={key} className="crypto">
						<span className="crypto coin" data-symbol={key}></span>
						<strong>1</strong><small>{key}</small> 
						<span> = </span>
						<strong><span data-currency="₩"></span> {value.toLocaleString()}</strong>
					</li>
					))}
				</ul>
			</div>
			)}

		</section>
	);
}

export default Front;
