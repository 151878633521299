import React, { useState, useEffect } from "react";
import axios from "axios";

const Test = () => {
  const [sessionData, setSessionData] = useState("");
  const [cookieValue, setCookieValue] = useState("");
  const [message, setMessage] = useState({});
	axios.defaults.withCredentials = true;


  const fetchSessionData = async () => {
    try {
      const response = await axios.get("/api/test");
			console.log(response.data);
			console.log(document.cookie);
      const { session_data, cookie_value, message } = response.data;

      setSessionData(session_data);
      setCookieValue(cookie_value);
      setMessage(message);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, []);

  return (
    <div className="m-4">
      <h1>Session and Cookie Test</h1>
      <p><strong>Session Data:</strong> {JSON.stringify(sessionData)}</p>
      <p><strong>Cookie Value:</strong> {JSON.stringify(cookieValue)}</p>
      <p>{message}</p>
    </div>
  );
};

export default Test;
