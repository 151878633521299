import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../auth";
import { avatar } from "../site";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";

import IconUser from "@mui/icons-material/AccountCircleTwoTone";
import IconOut from '@mui/icons-material/ExitToApp';
import IconDash from '@mui/icons-material/DashboardCustomizeOutlined';

export const Me = ({className, isMobile, navi}) => {
  const { auth, logout } = useAuth();
	const nav= (fn) => {
    const e=document.querySelector(".modal.show");
		if (e) {
      const modal=Modal.getInstance(e);
			if (modal) modal.hide();
    }; 
		fn();
  };

  if (!auth?.profile) {
    return (
      <button type="button" className={className} data-bs-toggle="modal" data-bs-target="#sign-in"><IconUser style={{color:"var(--cogle-color)",fontSize:isMobile?48:28}} /> 로그인</button>
    );
  }

	if (isMobile) {
		return (
			<div className="profile">
				<button type="button" className={`${className?className:""}`} onClick={()=>{nav(()=>navi("/profile"))}}>{avatar(auth.profile.avatar, {size:52} )}</button>
				<ul className="me">
					<li><Link to="/profile" className="ellipsis" onClick={()=>{nav(()=>navi("/profile"))}}>{auth.profile?.email}</Link></li>
					<li><button type="button" className="btn btn-sm" onClick={logout}><IconOut style={{fontSize:"1.3rem"}} />로그아웃</button></li>
				</ul>
			</div>
		);
	}
  return (
		<div className="dropdown">
			<button type="button" className={`dropdown-toggle${className?" "+className:""}`} data-bs-toggle="dropdown">{avatar(auth.profile?.avatar, {size:30} )}</button>
			<ul className="dropdown-menu">
				<li><Link to="/profile" className="dropdown-item" onClick={()=>{nav(()=>navi("/profile"))}}><IconUser style={{color:"var(--bs-success)"}} />내프로파일 <br /><small className="email">{auth.profile?.email}</small></Link></li>
				{auth?.isStaff &&(<li><Link to="/dash" className="dropdown-item" ><IconDash style={{color:"var(--bs-primary)"}} />관리자</Link></li>)}
				<li><button type="button" className="dropdown-item" onClick={logout}><IconOut /> 로그아웃</button></li>
			</ul>
		</div>
  );
};

