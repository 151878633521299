import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import { useAuth } from "../auth";

import IconEmail from "@mui/icons-material/AlternateEmailOutlined";
import IconPass from "@mui/icons-material/LockOpenOutlined";
import IconView from "@mui/icons-material/VisibilityOutlined";
import IconOff from "@mui/icons-material/VisibilityOff";
import IconForward from "@mui/icons-material/ArrowForward";
import IconSignup from "@mui/icons-material/AssignmentInd";

const SignIn = ({isMobile}) => {
	const {login, loginBy} = useAuth();
	const [pwv, setPwv] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	//const [error, setError] = useState(null);

	const closeModal = () => {
		const modal = document.querySelector("#sign-in.modal");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	};

  const dismiss = (e) => {
    if (e.target.closest(".panel") || e.target.closest(".copyright")) return;
		closeModal();
  };

	const fail = (data) => {
		if (data?.me && data?.status==="대기" && data?.email) {
			closeModal();
			sessionStorage.setItem("email",data.email);
			navigate("/profile",{ state:{code:data.me}});
			return null;
		}
		const form=document.querySelector("#sign-in form[name='sign-in']");
		form.classList.add("wiggle");
		setTimeout(()=>form.classList.remove("wiggle"),1000);
	}

	const submit = async (e) => {
    e.preventDefault();
		const payload = {
			"email":e.target.email.value,
			"password":e.target.password.value
		};
    await login(payload, closeModal, fail); // login 함수 호출
  };

	useEffect(() => {
		const modal = document.getElementById("sign-in");
		const masthead = document.getElementById("masthead")
		const showModal = () => {
			masthead.classList.add("alt");
			setIsModalOpen(true);
			AOS.refresh();
		};

		const hideModal = () => {
			setIsModalOpen(false);
			modal.classList.add("fade");
			masthead.classList.remove("alt");
		};

		modal.addEventListener("show.bs.modal", showModal); // aos 효과 적용
		modal.addEventListener("hide.bs.modal", hideModal);

		return () => {
			modal.removeEventListener("show.bs.modal", showModal);
			modal.removeEventListener("hide.bs.modal", hideModal);
		};
	}, []);

	return (
		<div className={`modal member${isMobile?" mobile":""}`} id="sign-in" data-bs-backdrop="static">
			<div className="modal-dialog modal-fullscreen" role="document">
				{isModalOpen && (
					<div className="modal-content" onClick={dismiss}>
						<div className="modal-body">
							<section className="sign-in">
								<div className="container">
									<div className="panel">
										<h1>코글<small className="text-primary"> - 로그인</small></h1>
										<p className="lead">{isMobile?"":"가상화폐 시장의 최신 소식을 확인하세요"}</p>
										<form method="post" name="sign-in" className="form" data-aos="fade-up" data-aos-delay="400" onSubmit={submit}>
											<fieldset>
												<legend>Please, enter your email and password for login.</legend>
												<ul>
													<li>
														<div className="input-group">
															<span className="input-group-text"><IconEmail />{isMobile?"":"이메일"}</span>
															<input name="email" type="email" className="form-control" minLength="8" required autoComplete="off" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" autoFocus placeholder={isMobile?"이메일":""} />
														</div>
														<div className="input-group">
															<span className="input-group-text"><IconPass />{isMobile?"":"비밀번호"}</span>
															<input name="password" type={pwv?"text":"password"} className="form-control" minLength="4" required autoComplete="off" placeholder={isMobile?"비밀번호":""} />
															<button type="button" className="input-group-text" onClick={()=>setPwv((prev)=>!prev)}>{pwv?<IconOff />:<IconView />}</button>
														</div>
													</li>
													<li><button type="submit" className="btn btn-danger"><IconForward style={{fontSize:"4.5rem",fontWeight:"bold"}} /></button></li>
												</ul>
											</fieldset>
										</form>
										<ul style={{flexWrap:"wrap"}}>
											<li><button className="social btn" type="button" onClick={() => loginBy("google",isMobile,closeModal)}><i className="google"></i> 구글</button></li>
											<li><button className="social btn" type="button" onClick={() => loginBy("naver",isMobile,closeModal)}><i className="naver"></i> 네이버</button></li>
											<li><button className="social btn" type="button" onClick={() => loginBy("kakao",isMobile,closeModal)}><i className="kakao"></i> 카카오</button></li>
											<li><button className="social btn" type="button" onClick={() => loginBy("facebook",isMobile,closeModal)}><i className="facebook"></i> 페이스북</button></li>
											<li><button className="social btn" type="button" onClick={() => loginBy("apple",isMobile,closeModal)}><i className="apple"></i> 애플</button></li>
										</ul>
										<div>
											<span className="text-primary">아직 회원이 아니신가요?</span>
											<button type="button" className="btn text-warning" data-bs-toggle="modal" data-bs-target="#sign-up"><IconSignup />계정 만들기</button>
										</div>
									</div>
									<div className="hero" data-aos="fade-up" data-aos-delay="300"><img src="https://s.cogle.io/i/whale.webp" alt="" className="img-fluid" /></div>
								</div>
								<div className="slant">
								</div>
							</section>
							<section className="social">
								<ul>
									<li><button className="sns facebook" type="button"></button></li>
									<li><button className="sns twitter" type="button"></button></li>
									<li><button className="sns google" type="button"></button></li>
									<li><button className="sns kakao" type="button"></button></li>
									<li><button className="sns naver" type="button"></button></li>
								</ul>
							</section>
						</div>
						<div className="modal-footer">
							<div className="container">
								<div className="copyright">
									<p>Copyright ©<time dateTime="2023">2023</time>All rights reserved. Made with <img src="https://s.cogle.io/i/heart.svg" className="heart" alt="heart" /> by <button type="button" data-bs-dismiss="modal">코글</button></p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default SignIn;
