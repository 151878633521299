import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../auth";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";
import axios from "axios";

import IconNoti from '@mui/icons-material/Emergency';
import IconSelect from '@mui/icons-material/PublishedWithChangesOutlined';
import IconAdd from '@mui/icons-material/PersonAddTwoTone';
import IconRemove from '@mui/icons-material/PersonAddDisabledTwoTone';


const Tag = ({ mobile,ids,handleTag }) => {

  const api = process.env.REACT_APP_API;
  axios.defaults.withCredentials = true;

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();

	const closeModal = () => {
		const modal = document.querySelector("#member-tag.modal");
    if (modal) {
      const bootstrapModal = Modal.getInstance(modal);
      if (bootstrapModal) bootstrapModal.hide();
    }
	};

	const select = (t) => {
		closeModal();
		handleTag(t);
	}
	const add = (id) => {
		alert(id);
	}
	const remove = (id) => {
		alert(id);
	}

  useEffect(() => {
		const fetchData = async () => {
			setError(null);
			setLoading(true);
			try {
				const response = await axios.get(`${api}/dash/tag`, {headers:{ Authorization: `Bearer ${auth?.profile?.token}`}});
				console.log(response.data);
				if (response.data.error) throw new Error(response.data.error);
				setData(response.data.data);
			} catch (err) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
		const target = document.getElementById("member-tag");
		if ( target ) target.addEventListener("show.bs.modal", fetchData);
		return () => target.removeEventListener("show.bs.modal", fetchData);
  }, []);

	return (
		<div className={`modal tag${mobile?" mobile":""}`} id="member-tag" >
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					{loading ? (<div className="loading"><span className="loader">Loading…</span></div> ) : (
					<>
					<div className="modal-header">
						<h5 className="modal-title"><i className="md-icon groups"></i> 회원 그룹 선택 / 관리</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div className="modal-body">
						<div className="alert alert-primary">선택된 회원 <strong className="text-danger">{ids.length}</strong> 명</div>
						<div>
							<small>
							<IconNoti style={{fontSize:"90%",color:"firebrick"}} />
							선택된 회원을 해당그룹으로 지정 <IconAdd style={{fontSize:"1.2rem",color:"var(--bs-success)"}} /> 하거나,
							해제 <IconRemove style={{fontSize:"1.2rem",color:"var(--bs-danger)"}} /> 할 수 있습니다
							</small>
						</div>
						<ul className="list-group list-group-flush">
							{data.map((e,n)=>(
							<li key={n} className="list-group-item">
								<button type="button" className="btn btn-sm" onClick={()=>select({i:e.id,n:e.name})}><IconSelect style={{fontSize:"1.1rem"}} /> {e.name}</button>
								<button type="button" className="btn btn-sm" onClick={()=>add(e.id)}><IconAdd style={{fontSize:"1.1rem",color:"var(--bs-success)"}} /></button>
								<button type="button" className="btn btn-sm" onClick={()=>remove(e.id)}><IconRemove style={{fontSize:"1.1rem",color:"var(--bs-danger)"}} /></button>
							</li>
							))}
						</ul>
					</div>
					</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Tag;
