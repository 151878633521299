import { useState, useCallback } from "react";
import { EditorState, AtomicBlockUtils, Modifier, convertFromRaw, SelectionState } from "draft-js";
import axios from "axios";

const useDraft = (api,prefix) => {
  const [state, setState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]); // 업로드된 파일 정보
  const [tmpFiles, setTmpFiles] = useState([]); // 임시 파일 경로
  const [data, setData] = useState({}); // 로드된 데이터
	axios.defaults.withCredentials = true;

	const marker = () => {
		const r = Math.random().toString(36).substring(2, 10);
		const t = Date.now().toString(36);
		return `≮${r}∦${t}≯`;
	};
	const blockMarker = marker();

	// 에디터 상태 업데이트 및 엔티티 정리
	const update = (editorState) => {
		const contentState = editorState.getCurrentContent();
		const blockMap = contentState.getBlockMap();
		// 현재 블록에서 사용 중인 엔티티 키 추출
		const usedEntityKeys = new Set();
		blockMap.forEach((block) => {
			const entityKey = block.getEntityAt(0);
			if (entityKey !== null) {
				usedEntityKeys.add(entityKey);
			}
		});
		/*
		// 모든 엔티티 키 확인
		const allEntityKeys = Object.keys(contentState.getEntityMap());
		// 사용되지 않는 엔티티 제거
		allEntityKeys.forEach((entityKey) => {
			if (!usedEntityKeys.has(entityKey)) {
				console.log(`무효화 처리된 엔티티: ${entityKey}`);
				// 엔티티 무효화 로직 추가 가능
			}
		});
		*/
		setState(editorState);
	};


  /* Atomic 블록 삽입- 업로드된 파일을 에디터에 추가 */
	const insert = (src, type) => {
		const contentState = state.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity(
			type.startsWith("image/") ? "image" : "media",
			"immutable",
			{ src }
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    // Atomic 블록 삽입
		const newEditorState = AtomicBlockUtils.insertAtomicBlock(
			EditorState.set(state, { currentContent: contentStateWithEntity }),
			entityKey,
			blockMarker, // 블록 사이 공백
		);
		setState(newEditorState);
	};
	
  const upload = async (event) => {
		const uploaded = Array.from(event.target.files);
		for (const file of uploaded) {
			const payload = new FormData();
			payload.append("prefix", prefix);
			payload.append("file", file);
			try {
				const response = await axios.post(`${api}/draft/upload`, payload);
				if (response.data.error) {
					alert(`파일 업로드 실패: ${file.name}`);
				} else {
					setTmpFiles((prev) =>[...prev,response.data.path]);
					setFiles((prev) => [ ...prev, {
						name: file.name,
						size: file.size,
						path: response.data.path,
						type: file.type,
					}]);
				}
			} catch (error) {
				console.error("파일 업로드 실패:", error);
			}
		}
	};

	const load = useCallback((data) => {
		if (data.content) {
			setData(data);
			try {
				//const contentState = stateFromHTML(data.content);
				const contentState = convertFromRaw(JSON.parse(data.content));
				const editorState = EditorState.createWithContent(contentState);

				// 커서를 마지막 블록의 끝으로 이동
				const selectionState = editorState.getSelection();
				const newEditorState = EditorState.forceSelection(
					editorState,
					selectionState.merge({
						anchorKey: contentState.getLastBlock().getKey(),
						focusKey: contentState.getLastBlock().getKey(),
						anchorOffset: contentState.getLastBlock().getText().length,
						focusOffset: contentState.getLastBlock().getText().length,
					})
				);

				setState(newEditorState);
			} catch (error) {
				console.error("Invalid content format:", error);
				setState(EditorState.createEmpty());
			}
		}
		setFiles(data.attachment??[]);
	}, []);

  /* 에디터 초기화 */
	const reset = () => {
		/*
		setState(EditorState.createEmpty());
		setFiles([]);
		setTmpFiles([]);
		*/
		const st = EditorState.createEmpty();
		const forced = EditorState.forceSelection(st,st.getSelection());
		setFiles([]); // 파일 상태 초기화
		setState(forced);
  };

	const removeBlockAndEntity = (blockKey) => {
		const contentState = state.getCurrentContent();
		const block = contentState.getBlockForKey(blockKey);
		const entityKey = block.getEntityAt(0);
		let newContentState = contentState;
		if (entityKey) {
			const selectionState = SelectionState.createEmpty(blockKey);
			try {
				newContentState = Modifier.applyEntity(newContentState, selectionState, null);
				console.log(`엔티티 ${entityKey} 무효화`);
			} catch (error) {
				console.warn(`엔티티 무효화 실패: ${entityKey}`, error);
			}
		}
		const newBlockMap = newContentState.getBlockMap().delete(blockKey);
		newContentState = newContentState.set("blockMap", newBlockMap);
		const newEditorState = EditorState.push(state,newContentState,"remove-range");
		console.log("삭제된 블록:", blockKey);
		setState(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
	};

	const render = (contentBlock) => {
		const type = contentBlock.getType();
		if (type === "atomic") {
			return { component: Media, editable: false, props: { onRemoveBlock: removeBlockAndEntity} };
		}
		return null; 
	};

	const Media = ({ contentState, block, blockProps }) => {
		const entityKey = block.getEntityAt(0);
		if (!entityKey) {
			console.warn("잘못된 엔티티 발견, 삭제 처리 중:", block.getKey());
			blockProps.onRemoveBlock(block.getKey());
			return null;
		}

		const entity = contentState.getEntity(entityKey);
		const { src } = entity.getData();
		const type = entity.getType();
		return (
			<>
			{type === "image" && <img src={src} alt="이미지" style={{ maxWidth: "100%" }} />}
			{type === "media" && (
				<video controls style={{ maxWidth: "100%" }}>
				<source src={src} type="video/mp4" />
				브라우저가 동영상을 지원하지 않습니다.
				</video>
			)}
			</>
		);
	};

	const handleKeyCommand = (command, editorState) => {
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		const blockKey = selection.getAnchorKey();
		const block = contentState.getBlockForKey(blockKey);

		if (command === "backspace" || command === "delete") {
			const nextBlockKey = command === "delete" ? contentState.getKeyAfter(blockKey) : null;
			const nextBlock = nextBlockKey ? contentState.getBlockForKey(nextBlockKey) : null;
			if (block.getType() === "atomic") {
				removeBlockAndEntity(blockKey);
				return "handled";
			}

			if (nextBlock && nextBlock.getType() === "atomic") {
				removeBlockAndEntity(nextBlockKey);
				return "handled";
			}
			if (block.getText().endsWith(blockMarker)) {
				const updatedContentState = Modifier.replaceText(
					contentState,
					selection.merge({
						anchorOffset: block.getText().length - blockMarker.length,
						focusOffset: block.getText().length,
					}),
					"" // 시스템 문자열 제거
				);
				const newEditorState = EditorState.push(
					editorState,
					updatedContentState,
					"remove-range"
				);
				setState(newEditorState);
				return "handled";
			}
		}
		return "not-handled";
	};

  // 파일 삭제 처리
  const remove = (index) => {
    const targetFile = files[index];
    // 서버에서 임시 파일 삭제
    axios.post(`${api}/draft/remove`, { path: targetFile.path });
    // 에디터에서 관련 블록 삭제
    const contentState = state.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const filteredBlockMap = blockMap.toSeq().filter((block) => {
      if (block.getType() === "atomic") {
        const entityKey = block.getEntityAt(0);
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          const { src } = entity.getData();
          return src !== targetFile.path;
        }
      }
      return true;
    });

    const newContentState = contentState.set("blockMap", filteredBlockMap.toOrderedMap());
    const ed = EditorState.push(state, newContentState, "remove-range");
    setState(ed);
    // 로컬 상태 업데이트
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const cancel = async () => {
		if (tmpFiles.length > 0) {
			try {
				const response = await axios.post(`${api}/draft/cancel`, { files:tmpFiles });
				if (response.data.error) {
					console.error("임시 파일 삭제 오류:", response.data.error);
				} else {
					reset();
				}
			} catch (error) {
				console.error("임시 파일 삭제 오류:", error);
			}
		}
		reset();
		load(data);
	};

  return {
    state,      // 에디터 상태
    update,     // 에디터 상태 업데이트 함수
    insert,     // Atomic 블록 삽입 함수
    upload,     // 파일 업로드 함수
    load,       // 콘텐츠 로드 함수
    reset,      // 에디터 초기화 함수
    files,      // 업로드된 파일 목록
		remove,
		render,
		cancel,
    handleKeyCommand, // 키 명령 핸들러
  };
};

export default useDraft;
