import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { Pagination } from "../../component/pagination";
import { formatNumber, ago, avatar } from "../../site";
import axios from "axios";

import Tag from "./tag";

import IconTag from "@mui/icons-material/PeopleRounded";
import IconSearch from "@mui/icons-material/Search";
import IconPc from '@mui/icons-material/DesktopMacTwoTone';
import IconMobile from '@mui/icons-material/TabletMacTwoTone';
import IconCheck from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import IconChecked from '@mui/icons-material/CheckBoxTwoTone';
import IconStaff from '@mui/icons-material/AdminPanelSettingsTwoTone';
import IconOnline from '@mui/icons-material/ExitToAppTwoTone';
import IconMessage from '@mui/icons-material/RateReview';
import IconAdd from '@mui/icons-material/PersonAdd';

import IconSort from '@mui/icons-material/UnfoldMore';
import IconDesc from '@mui/icons-material/Sort';

const Member = ({ isMobile }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [tag, setTag] = useState({ i: 0, n: "" });
  const [search, setSearch] = useState({ type: "nick", q: "" }); // 폼 상태
  const [applied, apply] = useState({ type: "nick", q: "" }); // 요청 상태
  const [staff, setStaff] = useState("hide");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState({ count: 0, page: 0 });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: null, order: null });
  const [selectedIds, setSelectedIds] = useState([]); // 선택된 ID 관리 상태
  const api = process.env.REACT_APP_API;
  axios.defaults.withCredentials = true;

  const searchType = { nick: "별명", email: "이메일", ip: "IP주소" };

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const params = {
        page: page,
        search: applied,
        sort,
        staff,
        tag: tag.i,
      };
      const response = await axios.get(`${api}/dash/member`, { headers:{ Authorization: `Bearer ${auth?.profile?.token}`}, params });
      console.log(response.data);
      if (response.data.error) throw new Error(response.data.error);
      setData(response.data.data);
      setTotal({ count: response.data.total, page: response.data.pages });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [api, page, applied, sort, staff, tag.i, auth?.profile?.token]);

  const handleSort = (field) => {
    setPage(1);
    setSort((prevSort) => {
      if (prevSort.field === field) {
        const nextOrder =
          prevSort.order === "desc"
            ? "asc"
            : prevSort.order === "asc"
            ? null
            : "desc";
        return { field: nextOrder ? field : null, order: nextOrder };
      }
      return { field, order: "desc" };
    });
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setPage(1);
    apply(search);
  };

  const handleTag = (t) => {
    setTag(t);
    setPage(1);
    fetchData();
  };

  const toggleSelect = (id) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedIds.length === data.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((item) => item.id));
    }
  };

  const handleLongPress = (id) => {
    if (!selectedIds.includes(id)) toggleSelect(id);
  };

  const List = ({ mobile, data, sort, handleSort }) => {
    const ymd = (d) => {
      const date = new Date(d);
      const ymd = new Intl.DateTimeFormat("ko-KR", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
      return ymd.replace(/\.\s+/g, "-").replace(/\.$/, "");
    };

    const SortIcon = ({ field }) => {
      if (sort.field === field) {
        return sort.order === "asc" ? <IconDesc style={{fontSize:"1rem",transform:"rotate(180deg)",color:"var(--bs-primary)"}} /> : <IconDesc style={{fontSize:"1rem",color:"var(--bs-success)"}} />;
      }
      return <IconSort style={{fontSize:"1rem"}} />;
    };

    if (mobile) {
      return (
        <div>
          <div className="sorting-options">
            <button className="btn btn-sm" onClick={() => handleSort("level")} data-sort={sort.field === "level" && sort.order}>등급 <SortIcon field="level" /></button>
            <button className="btn btn-sm" onClick={() => handleSort("xp")} data-sort={sort.field === "xp" && sort.order}>XP <SortIcon field="xp" /></button>
            <button className="btn btn-sm" onClick={() => handleSort("created_at")} data-sort={sort.field === "created_at" && sort.order}>가입일 <SortIcon field="created_at" /></button>
            <button className="btn btn-sm" onClick={() => handleSort("accessed_at")} data-sort={sort.field === "accessed_at" && sort.order}>마지막접속 <SortIcon field="accessed_at" /></button>
          </div>
          <ul className="mobile">
            {data.map((e, n) => (
              <li key={n}>
                <div className="card" style={{ marginBottom: "0.25rem" }} onContextMenu={(e) => { e.preventDefault(); handleLongPress(e.id); }}>
                  <h3 className="card-header">
                    <span>{avatar(e.avatar, { size: 22 })} {e.nick} <small className="name">{e.name}</small></span>
                    {selectedIds.includes(e.id) ? ( <IconChecked style={{ fontSize: "1.1rem", color: "var(--bs-success)" }} onClick={() => toggleSelect(e.id)} />
                    ) : ( <IconCheck style={{ fontSize: "1.0rem", color: "gray" }} onClick={() => toggleSelect(e.id)} />)}
                  </h3>
                  <ul className="card-body">
                    <li>{e.email}</li>
                    <li>{e.tel}</li>
                    <li>{e.tagged?.length > 0 && e.tagged.map(({ i, n }, idx) => (<span key={idx} onClick={() => handleTag({ i, n })} className="badge tag rounded-pill bg-danger">{n}</span>))}</li>
                    <li><span>레벨: {e.level}</span>, <span>경험치: {e.xp}</span></li>
                    <li>{e.status}</li>
                    <li><span className={`provider ${e.provider ?? ""}`}></span><time dateTime={e.created_at}> {ymd(e.created_at)}</time></li>
                    <li><time dateTime={e.accessed_at}>{ago(e.accessed_at)}</time>{e.accesses > 0 && (<span className="access">{e.accesses.toLocaleString()}</span>)}</li>
                    <li>{e.ip && (<span className="ip ellipsis">{e.mobile ? (<IconMobile style={{ fontSize: "1rem" }} />) : (<IconPc style={{ fontSize: "1rem" }} />)} {e.ip}</span>)}{e.online}</li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                {selectedIds.length === data.length ? (
                  <IconChecked style={{ fontSize: "1.2rem", color: "var(--bs-success)" }} onClick={toggleSelectAll} />
                ) : (
                  <IconCheck style={{ fontSize: "1.2rem", color: "gray" }} onClick={toggleSelectAll} />
                )}
              </th>
              <th scope="col">닉<small>/이름</small></th>
              <th scope="col">이메일</th>
              <th scope="col">그룹</th>
              <th scope="col" className="text-end" onClick={() => handleSort("level")} data-sort={sort.field === "level" && sort.order}>등급<SortIcon field="level" /></th>
              <th scope="col" className="text-end" onClick={() => handleSort("xp")} data-sort={sort.field === "xp" && sort.order}>포인트<SortIcon field="xp" /></th>
              <th scope="col">연락처</th>
              <th scope="col">상태</th>
              <th scope="col" className="text-end" onClick={() => handleSort("created_at")} data-sort={sort.field === "created_at" && sort.order}>가입<SortIcon field="created_at" /></th>
              <th scope="col" className="text-end" onClick={() => handleSort("accessed_at")} data-sort={sort.field === "accessed_at" && sort.order}>마지막접속<SortIcon field="accessed_at" /></th>
              <th scope="col">접속</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, n) => (
              <tr key={n}>
                <td className="text-center">
                  {selectedIds.includes(e.id) ? (
                    <IconChecked style={{ fontSize: "1.1rem", color: "var(--bs-success)" }} onClick={() => toggleSelect(e.id)} />
                  ) : (
                    <IconCheck style={{ fontSize: "1rem", color: "gray" }} onClick={() => toggleSelect(e.id)} />
                  )}
                </td>
                <td className="nick">{avatar(e.avatar, { size: 22 })} {e.nick} <small className="name">{e.name}</small></td>
                <td>{e.email}</td>
                <td>
                  {e.tagged?.length > 0 && e.tagged.map(({ i, n }, idx) => (
                    <span key={idx} onClick={() => handleTag({ i, n })} className="badge tag rounded-pill bg-danger">{n}</span>
                  ))}
                </td>
                <td className="text-end">{e.level}</td>
                <td className="text-end">{e.xp}</td>
                <td>{e.tel}</td>
                <td>{e.status}</td>
                <td className="text-end"><span className={`provider ${e.provider ?? ""}`}></span><time dateTime={e.created_at}> {ymd(e.created_at)}</time></td>
                <td className="text-end"><time dateTime={e.accessed_at}>{ago(e.accessed_at)}</time>{e.accesses > 0 && (<span className="access">{e.accesses.toLocaleString()}</span>)}</td>
                <td>{e.ip && (<span className="ip ellipsis">{e.mobile ? (<IconMobile style={{ fontSize: "1rem" }} />) : (<IconPc style={{ fontSize: "1rem" }} />)} {e.ip}</span>)}{e.online}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, sort, staff, fetchData]);


  if (loading) return (<div className="loading"><span className="loader">Loading…</span></div>);
  if (error) return (<div className="alert alert-danger">{error}<br />권한이 유효하지 않거나 만료되었습니다.<br />로그아웃 후 다시 로그인해 주세요.</div>);

  return (
    <section className={`dash member${isMobile ? " mobile" : ""}`}>
      <ul className="header">
        <li>
          {tag.i ? (
            <>
              <button type="button" className="btn btn-sm" data-bs-toggle="modal" data-bs-target="#member-tag"><IconTag /> 그룹:</button>
              <span className="badge tag rounded-pill bg-danger" onClick={()=>setTag({i:0,n:""})}>{tag.n}</span>
            </>
          ) : (
            <button type="button" className="btn btn-sm" data-bs-toggle="modal" data-bs-target="#member-tag"><IconTag /> 회원그룹</button>
          )}
        </li>
        <li>
          <div className="input-group">
            <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#send-message">
              <IconMessage style={{ fontSize: "0.95rem" }}/>{isMobile||(<>쪽지보내기</>)}
            </button>
            <button className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#add-member">
              <IconAdd style={{ fontSize: "0.95rem" }}/>{isMobile||(<>계정 만들기</>)}
            </button>
          </div>
        </li>
        <li>
          {staff==="hide"?(<IconStaff style={{color:"firebrick"}} onClick={()=>setStaff("show")} />):(<IconStaff style={{color:"forestgreen"}} onClick={()=>setStaff("hide")} />)}
        </li>
        <li>
          {isMobile ? (
            <>
              <IconSearch style={{ fontSize:"1.1rem"}} data-bs-toggle="dropdown" />
              <div className="dropdown-menu">
                <form name="search" className="form form-inline" onSubmit={handleSearchSubmit}>
                  <fieldset className="input-group input-group-sm">
                    <button className="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button">{searchType[search.type]}</button>
                    <ul className="dropdown-menu fade">
                      {Object.entries(searchType).map(([n, t]) => (
                        <li key={n} className="form-check dropdown-item" onClick={() => setSearch((prev) => ({ ...prev, type: n }))}>
                          <label className="form-check-label"><input name="type" type="radio" value={n} className="btn-check" /> {t} </label>
                        </li>
                      ))}
                    </ul>
                    <input type="search" name="q" autoComplete="off" minLength="2" className="form-control" value={search.q} onChange={(e) => setSearch((prev) => ({ ...prev, q: e.target.value }))} required />
                    <button className="btn btn-sm btn-primary" type="submit"><IconSearch style={{ fontSize: "0.95rem" }} /></button>
                  </fieldset>
                </form>
              </div>
            </>
          ) : (
            <form name="search" className="form form-inline" onSubmit={handleSearchSubmit}>
              <fieldset className="input-group input-group-sm">
                <button className="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button">{searchType[search.type]}</button>
                <ul className="dropdown-menu fade">
                  {Object.entries(searchType).map(([n, t]) => (
                    <li key={n} className="form-check dropdown-item" onClick={() => setSearch((prev) => ({ ...prev, type: n }))}>
                      <label className="form-check-label"><input name="type" type="radio" value={n} className="btn-check" /> {t} </label>
                    </li>
                  ))}
                </ul>
                <input type="search" name="q" autoComplete="off" minLength="2" className="form-control" value={search.q} onChange={(e) => setSearch((prev) => ({ ...prev, q: e.target.value }))} required />
								<button className="btn btn-sm btn-primary" type="submit"><IconSearch style={{ fontSize: "0.95rem" }} /></button>
							</fieldset>
						</form>
					)}
				</li>
      </ul>
      {data.length > 0 && <List mobile={isMobile} data={data} sort={sort} handleSort={handleSort} />}
      <Pagination
        load={(n) => setPage(n)}
        page={page}
        total={total.page}
        options={{ prefix: "+", tab: isMobile ? 1 : 2 }}
      />
			<Tag mobile={isMobile} ids={selectedIds} handleTag={handleTag} />
			<div id="send-message" className="modal"><div className="modal-dialog"><div className="modal-content"><h5>쪽지 보내기</h5></div></div></div>
			<div id="add-member" className="modal"><div className="modal-dialog"><div className="modal-content"><h5>회원 계정 만들기</h5></div></div></div>
    </section>
  );
};
export default Member;
