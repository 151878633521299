import React, { useState, useEffect, useCallback, useRef } from "react";
import { formatNumber } from "../site";

const HeadUp = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const api = process.env.REACT_APP_API;
  const intervalRef = useRef(null); // interval ID를 저장

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${api}/head-up`,{credentials: "include"});
      if (response.error) throw new Error(response.error);
      const result = await response.json();
      setData(result);
      setError(null); // 오류 상태 초기화
    } catch (err) {
      console.error(err);
      setError("데이터를 불러오는 중 오류가 발생했습니다.");
    }
  }, [api]);

  useEffect(() => {
    fetchData();
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (!error) intervalRef.current = setInterval(fetchData, 10000);
    return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [fetchData, error]);

  if (error) return <section id="head-up">{error}</section>;
  if (!data) return <section id="head-up">Loading…</section>;

  const outdated = data.ago < -1200; // 데이터가 오래된 경우 판단

  return (
    <section id="head-up">
      <ul className={`crypto coin${outdated ? " outdated" : ""}`} data-symbol="btc">
        {Object.entries(data.BTC).map(([exchange, e]) => (
          <li
            key={exchange}
            data-x={exchange}
            data-currency={e.symbol}
            data-change={e.percent > 0 ? "+" : "-"}
          >
            {formatNumber(e.last)}
            <small>{e.percent}%</small>
          </li>
        ))}
        <li
          className="kimp"
          data-change={data.kimp.change > 0 ? "+" : "-"}
        >
          <span>김프:</span>
          {data.kimp.premium.toFixed(2)}
          <small>%</small>
        </li>
      </ul>
    </section>
  );
};

export default HeadUp;

