import React from "react";

const Lost = ({message}) => {
	return (
		<section className="lost">
			<div className="container">
				{/*<h1>이 페이지는 깊은 바다로 사라졌어요 !</h1>*/}
				{message?message:(<h1>열심히 헤엄치고 있어요, 곧 도착합니다 !</h1>)}
				<a href="/" className="button">홈으로 돌아가기</a>
				<div className="whale"><img src="https://s.cogle.io/i/lost-whale.webp" alt="Lost whale" /></div>
			</div>
			<div className="waves"></div>
		</section>
	);
};
export default Lost;
