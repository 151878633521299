import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Fng, Alt, Dominance } from "../../component/chart";
import { loadCss } from "../../site";

const Chart = ({ isMobile }) => {
  const loading = useRef(true); // 로딩 상태
  const [data, setData] = useState([]); // 코인 데이터
  const [error, setError] = useState(null); // 에러 상태
  const [stylized, stylize] = useState(false); // 로딩 상태
  const api = process.env.REACT_APP_API;
	axios.defaults.withCredentials = true;

	const fetchData = useCallback(async () => {
		setError(null); // 에러 초기화
	 	setData({});
		try {
			const response = await axios.get(`${api}/insight/chart`);
			if (response.data.error) throw new Error("데이터 로드 실패");
			//console.log(response.data.data);
			setData(response.data.data);
		} catch (err) {
			setError(err.message);
		} finally {
			loading.current = false;
		}
	}, [api]);

  useEffect(() => {
		if (loading.current) {
			loading.current = false;
			fetchData();
		}
  }, [fetchData]);

  useEffect(() => {
		return loadCss("/css/insight.css",()=>stylize(true));
	}, []);

	if (!stylized) return null;
  if (error) return <div className="alert alert-danger">데이터를 불러오는 중 오류가 발생했습니다: {error}</div>;
	//if (loading.current) return <div className="loading"><span className="loader">Loading…</span></div>;

  return (
    <section className={`insight chart${isMobile?" mobile":""}`}>
			<Helmet>
        <title>코글 - 코인 도미넌스</title>
      </Helmet>
      <h1>코인 도미넌스</h1>
			{data.fng&&(
			<div className="card">
				<h3 className="card-header">공포 탐욕 지수</h3>
				<div className="card-body"><Fng data={data.fng} /></div>
			</div>
			)}
			{data.alt&&(
			<div className="card mt-4">
				<h3 className="card-header">알트코인 시즌</h3>
				<div className="card-body"><Alt value={data.alt} /></div>
			</div>
			)}
			{data.btc&&(
			<div className="card mt-4">
				<h3 className="card-header">비트코인 도미넌스</h3>
				<div className="card-body"><Dominance data={data.btc} /></div>
			</div>
			)}
    </section>
  );
};

export default Chart;
