import React, { useState, useEffect } from "react";
import { Editor, convertToRaw } from "draft-js";
import useDraft from "../draft";
import axios from "axios";

import IconDelete from "@mui/icons-material/Delete";
import IconInsert from "@mui/icons-material/PostAdd";
import IconAttach from "@mui/icons-material/AttachFile";

const Add = ({ isMobile, forum, auth, onClose }) => {
  const api = process.env.REACT_APP_API;
  const { state, update, reset, files, cancel, remove, upload, insert, render, handleKeyCommand  } = useDraft(api, forum);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
	const [header, setHeader] = useState("");
	axios.defaults.withCredentials = true;

	const submit = async (event) => {
    event.preventDefault();

    try {
			const contentState = state.getCurrentContent();
			const plainText = contentState.getPlainText(); // 전체 텍스트 가져오기
			if (!plainText.trim()) throw new Error("내용을 입력하세요");
		
			const content = JSON.stringify(convertToRaw(contentState));
			const payload = new FormData();
			payload.append("member_id", auth?.id ?? 0);
			payload.append("title", title);
			payload.append("content", content);
			payload.append("attachment", JSON.stringify(files));
			if (forum==="notice") payload.append("is_staff", auth?.isStaff??false);

      const response = await axios.post(`${api}/${forum}/add`, payload);
			console.log(response.data);
      if (response.data.error) throw new Error(response.data.error);
			setTitle("");
			reset();
			onClose(true);
    } catch (error) {
      console.error("폼 제출 오류:", error);
			console.log(event.target);
			const form=event.target;
			setError(`${error}`);
			setTimeout(()=>setError(false), 2000);
			form.classList.add("wiggle");
			setTimeout(()=>form.classList.remove("wiggle"), 1000);
    }
  };

  const undo = () => {
    cancel();
		reset();
    onClose(false);
  };

  useEffect(() => {
		switch (forum) {
			case "notice":
				setHeader("공지사항 - 새 공지사항");
				break;
			case "lounge":
				setHeader("자유게시판 - 새 글쓰기");
				break;
			case "meme":
				setHeader("짤의 성지 - 짤 올리기");
				break;
			default :
				break;
		}
  }, [forum]);



  return (
		<div className="modal" id={`add-${forum}`} data-bs-backdrop="static">
			<div className="modal-dialog modal-lg" role="document">
				<form className="modal-content form" onSubmit={submit}>
          <div className="modal-header">
						<h4 className="modal-title">{header}</h4>
						<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div className="modal-body">
						<input type="text" name="title" className="form-control" placeholder="제목을 입력하세요" value={title} required onChange={(e) => setTitle(e.target.value)} />
						<div className="editor form-control">
							<Editor editorState={state} onChange={update} blockRendererFn={render} handleKeyCommand={handleKeyCommand} />
						</div>
						<div className="browse">
							<button className="btn btn-sm btn-primary"><IconAttach style={{fontSize:"1.1rem"}}/>파일 첨부</button>
							<input type="file" multiple onChange={upload} />
						</div>
						{files.length > 0 && (
						<ul className="attachment">
							{files.map((file, index) => (
							<li key={index} className="file">
								<div className="ellipsis">{file.name}</div>
								<div className="size">{(file.size / 1024).toFixed(2)} KB</div>
								<button type="button" className="btn btn-sm text-danger" onClick={() => remove(index)}><IconDelete style={{fontSize:"1.1rem"}} /><small>삭제</small></button>
								{(file.type.startsWith("image/") || file.type.startsWith("video/")) && (
								<button type="button" className="btn btn-sm text-primary" onClick={() => insert(file.path, file.type)}><IconInsert style={{fontSize:"1.1rem"}} /><small>내용에 추가</small></button>)}
							</li>
							))}
						</ul>
            )}
						{error&&(<div className="alert alert-danger">{error}</div>)}
					</div>
					<div className="modal-footer">
						<div className="input-group input-group-sm">
						<input type="reset" className="btn btn-secondary px-4" data-bs-dismiss="modal" value="닫기" onClick={undo} />
						<button type="submit" className="btn btn-success px-4">저장하기</button>
						</div>
					</div>
        </form>
			</div>
		</div>
  );
};

export default Add;
