import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../auth";
import axios from "axios";
import Lost from "../page/lost";

const Activate = ({ token }) => {
  const { auth, activate } = useAuth();
  const [activated, setActivated] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;

  const message = (error) => (
    <>
      <h1>{error}</h1>
      <p>받은 메일의 확인 링크를 확인해 주세요</p>
    </>
  );

  useEffect(() => {
    if (!activated && token) {
      const activateAccount = async () => {
        try {
          const response = await axios.get(`${api}/member/activate`, { params: { token } });
          const data = response.data;
          if (data.error) {
            setError(data.error);
            return;
          }
          if (data.activate && data.token) {
            activate(data.token);
          }
          setActivated(true);
        } catch (err) {
          setError("서버 오류가 발생했습니다.");
        }
      };
      activateAccount();
    }
  }, [token, activated, activate, api]);

  useEffect(() => {
    if (activated) navigate("/profile");
  }, [activated, navigate]);

  if (error) {
    if (auth?.id > 0) navigate("/profile");
    else return <Lost message={message(error)} />;
  }

  return (
    <section className="activate">
      <Helmet>
        <title>코글 - 가입 활성화</title>
      </Helmet>
      <h1>가입 이메일 확인</h1>
      {activated ? (
        <div className="alert alert-success">
          <h4>이메일이 확인되었습니다!</h4>
          <p>🎉 가입을 환영합니다! 이제 Cogle.io의 회원으로 최신 정보를 누리세요!</p>
        </div>
      ) : (
        <p>이메일 확인 중입니다...</p>
      )}
    </section>
  );
};

export default Activate;
